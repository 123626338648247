import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, FormControl, FormGroup, FormControlLabel, InputLabel, Select, Container, Grid, MenuItem, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from "react-redux";
import { postSeguridadAsync, seguridadAuthenticated, seguridadStatus } from 'app/features/Seguridad/SeguridadSlice';
import { firmantesList, getFirmantesAsync, } from "app/features/Firmante/firmanteSlice";
import { funcionList, getFuncionAsync  } from 'app/features/Funcion/FuncionSlice';
import { cargosList, getCargoAsync } from "app/features/Cargo/cargoSlice";
import { modalidadList, getModalidadAsync } from "app/features/Modalidad/ModalidadSlice";
import { delegacionList, getDelegacionAsync } from "app/features/Delegacion/DelegacionSlice";
import { actoAdministrativoList, getActoAdministrativoAsync } from "app/features/ActoAdministrativo/ActoAdministrativoSlice";
import { getFiltroSellosAsync, filtroDataSearch, setDataSearch, filtroSellosStatus } from "app/features/Filtros/filtroSellosSlice";
import TableModificacionSellos from "components/TableModificacion";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {useLocation} from 'react-router-dom';
import {ROUTES} from '../../constants/routes/internals';

const useStyles = makeStyles((theme) => ({

  tituloCabecera:{
    paddingBottom: 50
  },

  containermt: {
    marginTop: 60,
  },
  card: {
    padding: 30,
  },
  avatar: {
    backgroundColor: "#0f80aa",
    width: 80,
    height: 80,
  },
  icono: {
    fontSize: 50,
  },
  form: {
    marginTop: 40,
    marginBottom: 10,
  },
  gridmb: {
    margin: 0,
  },

  espacioCombo: {
    padding: 10,
  },

  gridmb2: {
    paddingTop: 10,
  },
  inpuText: {
    padding: 10,
  },
  link: {
    marginTop: 10,
    fontSize: "1.1rem",
    fontFamily: "Roboto",
    lineHeight: 1.5,
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  appBar: {
    paddingTop: 8,
    paddingBottom: 8,
  },
  grow: {
    flexGrow: 0, //sirve para especificar el valor de crecimiento
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
    },
  },
  linkAppBarLogo: {
    display: "inline-flex",
    alignItems: "center",
    color: "inherit",
    textDecoration: "none",
  },
  mr: {
    marginRight: 3,
  },
  buttonIcon: {
    fontSize: 14,
    padding: 0,
  },
  linkAppBarDesktop: {
    display: "inline-flex",
    alignItems: "center",
    padding: "6px 16px",
    color: "inherit",
    textDecoration: "none",
  },
  list: {
    width: 250,
  },

  listItem: {
    padding: 0,
  },
  linkAppBarMovil: {
    display: "inline-flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 16px",
    color: "inherit",
    textDecoration: "none",
  },

  ListItemIcon: {
    minWidth: 35,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  text_title: {
    fontWeight: 600,
    color: "#494949",
    marginBottom: 10,
  },

  media: {
    height: 250,
    backgroundColor: "#F2F2F2",
    margin: "15px 15px 0 15px",
  },

  price: {
    float: "right",
    padding: "0 20px 0 20px",
    backgroundColor: "#0f8aa0",
  },

  text_card: {
    fontWeight: "bold",
    color: "#656565",
    marginBottom: 8,
  },
  paperImg: {
    backgroundColor: "#F2F2F2",
  },

  mediaDetalle: {
    width: 380,
    height: 380,
    margin: "auto",
  },

  text_detalle: {
    fontWeight: 500,
    color: "#494949",
    marginBottom: 5,
  },

  imgProductoCC: {
    backgroundColor: "#F2F2F2",
    width: 80,
    height: 70,
  },

  papperPading: {
    padding: 20,
  },

  divideTop: {
    marginBottom: 20,
  },
  gridPC: {
    margin: "auto",
    marginTop: 20,
  },

  buttonAnterior: {
    marginRight: 8,
  },
  formControl: {
    margin: 12,
  },
  gridLR: {
    paddingLeft: 30,
    paddingBottom: 20,
    paddingRight: 30,
  },
  divider: {
    marginTop: 12,
    marginBottom: 12,
  },
  imgProductoPC: {
    backgroundColor: "#F2F2F2",
    width: 50,
    height: 40,
  },

  espacioBotonesFinales: {
    paddingTop: 50,
  },

  espacioNro: {
    paddingTop: 62,
  },

  espacioMensaje: {
    padding: 10,
  },

  espacioCheckbox:{
    paddingTop: 25,
},
}));

const FiltroSellosModificar = () => {


  //TRAEMOS LAS RUTAS PARA SER ENVIADAS AL BACKEND COMO MEDIDA DE SEGURIDAD PARA PERMISOS
  let rutaPermiso = ROUTES.MODIFICARSELLO.CLASS_NAME;

  /*VAMOS A PROCEDER A PASAR DOS PARAMETROS CADA VEZ QUE ENTRAMOS AL ALTA DE SELLOS
    LA CUAL NOS PERMITIRA VALIDAR EL USUARIO Y EL PASSWORD QUE VIENE EN LA URL. PARA LO CUAL
    VAMOS A PROCEDER A CREAR EL HOOK USE PARAMS DE REACT ROUTER DOM QUE NOS PERMITIRA 
    RECUPERAR LOS DOS PARAMETROS QUE NOS ENVIA LA URL
  */
    //const {username, password} = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let codUsuModificar = query.get('x');
    let clave = query.get('y');

    let decodeCodUsu = decodeURI(codUsuModificar);
    let decodeClave = decodeURI(clave);

    // replace() usando una expresión Regex para reemplazar espacios en blanco
    decodeCodUsu = codUsuModificar.replace(/\s+/g, '+');

    // replace() usando una expresión Regex para reemplazar espacios en blanco
    decodeClave = clave.replace(/\s+/g, '+');

  //desestructuro el objeto
  const classes = useStyles();
  const dispatch = useDispatch();

  const Authenticated = useSelector(seguridadAuthenticated);
  const statusModificar = useSelector(seguridadStatus);
  
  const [codUsu, setFirmante] = useState("");
  const [codFuncion, setFuncion] = useState(-1);
  const [codCargo, setCargo] = useState("");
  const [codModalidad, setModalidad] = useState("");
  const [codEnt, setDelegacion] = useState(-1);
  const [codActoAdministrativo, setCodActoAdministrativo] = useState("");
  const [nroActoAdministrativo, setNroAdministrativo] = useState("");
  const [activoSello, setActivoSello] = useState(true);
  const [vigente, setVigente] = useState("");
  const [page, setPage] = useState(0);
  

  //useSelector llamas a los datos
  const FirmantesList = useSelector(firmantesList);
  const FuncionList = useSelector(funcionList);
  const CargosList = useSelector(cargosList);
  const ModalidadList = useSelector(modalidadList);
  const DelegacionList = useSelector(delegacionList);
  const ActoAdministrativoList = useSelector(actoAdministrativoList);
  const status = useSelector(filtroSellosStatus);

  const onChangeFirmante = (e, value) => {
    if (value !== null) {
      const opcion = value.value;
      setFirmante(opcion);
  } else {
      setFirmante("");
  }
  };

  const onChangeCargos = (e) => {
    setCargo(e.target.value);
  };

  const onChangeModalidad = (e) => {
    setModalidad(e.target.value);
  };

  const onChangeDelegacion = (e) => {
    setDelegacion(e.target.value);
  };

  const onChangeActoAdministrativo = (e) => {
    setCodActoAdministrativo(e.target.value);
  };

  const onChangeActivoSello = (e) =>{
    setActivoSello(!activoSello);
  }

  const onChangeVigente = (e)=>{
    setVigente(e.target.value);
  }

  const onChangeFuncion = (e)=>{
    setFuncion(e.target.value);
  }

  const handleEventSearch = () => {
    const data = {
      codUsu,
      codEnt,
      codModalidad,
      codFuncion,
      codActoAdministrativo,
      nroActoAdministrativo,
      vigente,
      activoSello,
      encryptedUser: decodeCodUsu,
      encryptedPassword: decodeClave
    };
    //llamo a la funcion del Slice
    dispatch(setDataSearch(data));
    dispatch(getFiltroSellosAsync(data));
    setPage(0);
  };

    //EN ESTA LINEA DE CODIGO PASAMOS EL FIRMANTE MEDIANTE UN PUSH Y AGREGAMOS EL LABLE Y EL VALUE
      //LOS CUALES SERAN RECUPERADOS EN EL CONTROL AUTOCOMPLETE
      let firmanteObjeto = [];
      FirmantesList.map((firmante, index) => firmanteObjeto.push({ label: firmante.apellido + " " + firmante.nombre, value: firmante.codUsu}))


  useEffect(() => {
    const data = {
      codUsu: decodeCodUsu,
      clave: decodeClave,
      ruta: rutaPermiso
    }

    dispatch(postSeguridadAsync(data));
    if(Authenticated){
      if (FirmantesList.length === 0) {
        //llamo a la funcion del firmateSlice
        dispatch(getFirmantesAsync());
      }
  
      if (CargosList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getCargoAsync());
      }
  
      if (ModalidadList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getModalidadAsync());
      }
  
      if (DelegacionList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getDelegacionAsync());
      }
  
      if (FuncionList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getFuncionAsync());
      }
  
      if (ActoAdministrativoList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getActoAdministrativoAsync());
      }
    }
    
  }, []);


  return (
    <Container className={classes.containermt}>
       
       {statusModificar === 'loading' ? ''
        : 
      <Grid container justifyContent="center">
        <Grid item lg={12} md={8}>
          <Card className={classes.card} align="center">
            <Typography className={classes.tituloCabecera} variant="h4">MODIFICACIÓN DE SELLOS</Typography>
            {!Authenticated ? 
            <div>
                Usuario no autenticado
            </div>
            :
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={2}>
                <Grid item md={5} xs={12} className={classes.gridmb}>
                  
                  <InputLabel align="left">Firmante</InputLabel>
                  {/* <FormControl fullWidth>         
                    <Select                     
                      align="left"
                      value={codUsu}
                      variant="standard"
                      onChange={(e) => onChangeFirmante(e)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Todos</em>
                      </MenuItem>
                      {FirmantesList.map((firmante, index) => (
                      <MenuItem key={index} value={firmante.codUsu}>
                        {firmante.apellido + " " + firmante.nombre}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl> */}

                  <Autocomplete
                            disablePortal
                            name='Firmantes'
                            onChange={onChangeFirmante}
                            options={firmanteObjeto}
                            noOptionsText={'No se encuentra el Firmante'}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} placeholder='Todos' variant="standard" />}
                  />

                  
                 
                <InputLabel align="left">Función</InputLabel>
                    <FormControl fullWidth>         
                    <Select                     
                      align="left"
                      value={codFuncion}
                      variant="standard"
                      onChange={(e) => onChangeFuncion(e)}
                      displayEmpty
                    >
                      <MenuItem value={-1}>
                        <em>Todos</em>
                      </MenuItem>
                      {FuncionList.map((funcion, index) => (
                                <MenuItem key={index} value={funcion.idFuncion}>
                                    {funcion.descFuncion}
                                </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <InputLabel align="left">Modalidad</InputLabel>
                  <FormControl fullWidth>         
                    <Select                     
                      align="left"
                      value={codModalidad}
                      variant="standard"
                      onChange={(e) => onChangeModalidad(e)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Todas</em>
                      </MenuItem>
                      {ModalidadList.map((modalidad, index) => (
                      <MenuItem key={index} value={modalidad.idModalidad}>
                        {modalidad.descModalidad}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={5} xs={12} className={classes.gridmb}>
                  
                  <InputLabel align="left">Delegación</InputLabel>

                  <FormControl fullWidth>         
                    <Select                     
                      align="left"
                      value={codEnt}
                      variant="standard"
                      onChange={(e) => onChangeDelegacion(e)}
                      displayEmpty
                    >
                      <MenuItem value={-1}>
                        <em>Todas</em>
                      </MenuItem>
                      {DelegacionList.map((d, index) =>(
                                <MenuItem key={index} value={d.codEnt}>{d.razonSocial}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>


                  <InputLabel align="left">Acto Administrativo</InputLabel>
                  <FormControl fullWidth>         
                    <Select                     
                      align="left"
                      value={codActoAdministrativo}
                      variant="standard"
                      onChange={(e) => onChangeActoAdministrativo(e)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Todos</em>
                      </MenuItem>
                      {ActoAdministrativoList.map((actoAdministrativo, index) => (
                      <MenuItem key={index} value={actoAdministrativo.idActoAdministrativo}>
                        {actoAdministrativo.descActoAdministrativo}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>

                  <InputLabel align="left">Vigente</InputLabel>
                  <FormControl fullWidth>         
                    <Select                     
                      align="left"
                      value={vigente}
                      variant="standard"
                      onChange={(e) => onChangeVigente(e)}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <em>Todos</em>
                      </MenuItem>
                      <MenuItem value="SI">SI</MenuItem>
                      <MenuItem value="NO">NO</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item md={2} xs={12} className={classes.gridmb}>
                  <div className={classes.espacioNro}>
                    <TextField
                      label="Nro."
                      variant="standard"
                      fullWidth
                      name="numero"
                    />
                  </div>

                  <div>
                  <FormGroup className={classes.espacioCheckbox}>
                      <FormControlLabel 
                      control={
                      <Checkbox onChange={(e) => onChangeActivoSello(e)} defaultChecked={true} value={activoSello} />
                      } label="Activo" />
                  </FormGroup>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.espacioBotonesFinales}>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                
                <Button
                  color="success"
                  variant="contained"
                  onClick={handleEventSearch}
                  fullWidth
                >
                  Buscar
                </Button>

              </Grid>
            </Grid>
            </form>
            }
          </Card>
        </Grid>
      </Grid>

      }
      
      {/* AQUI SE ENCUENTRA EL COMPONENTE DE LA TABLA DE CONSULTA DE SELLOS - DESARROLLADO POR - ANDRES VIERA  */}
      <TableModificacionSellos  page={page} setPage={setPage} />
      
    </Container>
  );
};

export default FiltroSellosModificar;