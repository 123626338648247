import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSellos, postSellos, putSellos  } from 'app/features/Filtros/filtroSellosAPI';
const initialState = {
  List: [],
  status: 'idle',
  selloSeleccionado: {},
  dataSearch:{}
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getFiltroSellosAsync = createAsyncThunk(
  'filtroSellos/fetchFiltroSellos',
  async (data) => {
    const response = await getSellos(data);
    return response.data;
  }
);


export const postFiltroSellosAsync = createAsyncThunk(
  'filtroSellos/fetchFiltroSellosPost',
  async (data) => {
    const response = await postSellos(data);
    return response.data;
  }
);


export const putFiltroSellosAsync = createAsyncThunk(
  'filtroSellos/fetchFiltroSellosPut',
  async (data) => {
    const response = await putSellos(data);
    return response.data;
  }
);

export const filtroSellosSlice = createSlice({

  name: 'filtroSellos',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    setSelloSeleccionado: (state, action) => {
      state.selloSeleccionado = action.payload;
    },

    setDataSearch: (state, action) => {
      state.dataSearch = action.payload;
    },

    
    guardarSelloSeleccionado: (state, action) => {
      
      const selloToSave = {
        idSello:action.payload.idSello,
        codEnt: action.payload.codEnt,
        codCargo: action.payload.codCargo,
        codFuncion: action.payload.codFuncion,
        codModalidad: action.payload.codModalidad,
        codEntidad: action.payload.codEntidad,
        codActoAdministrativo: action.payload.codActoAdministrativo,
        descActoAdministrativo: action.payload.descActoAdministrativo,
        vigenciaDesde: action.payload.vigenciaDesde,
        vigenciaHasta: action.payload.vigenciaHasta,
        nroActoAdministrativo: action.payload.nroActoAdministrativo,
        activoSello: action.payload.activoSello,
        encryptedUser: action.payload.EncryptedUser,
        encryptedPassword: action.payload.EncryptedPassword
      };
      if(action.payload?.idSello > 0){
        putFiltroSellosAsync(selloToSave);
      }else{
        postFiltroSellosAsync(selloToSave);
      }
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getFiltroSellosAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFiltroSellosAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.List = action.payload;
      })
      .addCase(putFiltroSellosAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putFiltroSellosAsync.fulfilled, (state, action) => {
        state.status = 'registro actualizado!';
      })
  },
});

export const { setSearch, setDataSearch, setSelloSeleccionado, guardarSelloSeleccionado } = filtroSellosSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.<nameReducer>.<Key_of_state>)`
export const filtroSellosList = (state) => state.filtroSellosReducer.List;
export const filtroSellosStatus = state => state.filtroSellosReducer.status;
export const filtroSellosSeleccionado = (state) => state.filtroSellosReducer.selloSeleccionado;
export const filtroDataSearch = (state) => state.filtroSellosReducer.dataSearch;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default filtroSellosSlice.reducer;