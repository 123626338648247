import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPeliculas } from './peliculasAPI';

const initialState = {
  Lista: [],
  search: '',
  status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getPeliculasAsync = createAsyncThunk(
  'peliculas/fetchPeliculas',
  async (amount, { getState }) => {
    const state = getState();
    const response = await fetchPeliculas(state.peliculasReducer.search);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const peliculasSlice = createSlice({
  name: 'peliculas',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSearch: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.search = action.payload;
    },
    // decrement: (state) => {
    //   state.value -= 1;
    // },
    // // Use the PayloadAction type to declare the contents of `action.payload`
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload;
    // },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPeliculasAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPeliculasAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        console.log(action.payload)
        state.Lista = action.payload;
      });
  },
});

export const { 
  setSearch, 
  //  decrement, 
  //  incrementByAmount 
} = peliculasSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.<nameReducer>.<Key_of_state>)`
export const selectPeliculas = (state) => state.peliculasReducer.Lista;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount) => (dispatch, getState) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default peliculasSlice.reducer;
