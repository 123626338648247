import React from 'react';
import {useFetch} from '../../hooks/useFetch';
import { makeStyles } from "@mui/styles";

const SelectList = ({title, url, handleChange}) => {
    const useStyles = makeStyles((theme) => ({
        selectAnidados: {
          paddingTop: 50,
        }   
      }));

      const classes = useStyles();

      const [data, error, loading] = useFetch(url);
      console.log(data,error,loading);  

      if(!data) return null;

      let id = `select-${title}`;

    return (
        <>
        <label htmlFor={id}>{title}</label>
        <div className={classes.selectAnidados}>
            <select name={id} id={id}>
                <option value="">--------</option>
            </select>
        </div>
        </>       
    );
};

export default SelectList;