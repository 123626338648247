import { Alert, Button, Card, Checkbox, FormGroup, FormControlLabel, Container, Dialog, DialogContent, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { createPortal } from 'react-dom'
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import 'date-fns';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { filtroSellosSeleccionado, putFiltroSellosAsync } from 'app/features/Filtros/filtroSellosSlice';
import { firmantesList, getFirmantesAsync  } from 'app/features/Firmante/firmanteSlice';
import { modalidadList, getModalidadAsync } from 'app/features/Modalidad/ModalidadSlice';
import { delegacionList, getDelegacionAsync } from 'app/features/Delegacion/DelegacionSlice';
import { funcionList, getFuncionAsync } from 'app/features/Funcion/FuncionSlice';
import { actoAdministrativoList } from 'app/features/ActoAdministrativo/ActoAdministrativoSlice';
import { cargosList } from "app/features/Cargo/cargoSlice";
import moment from 'moment';
import {useLocation} from 'react-router-dom';
import VistaSello from 'components/vistaSello';
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    containermt: {
        marginTop: 60
    },
    card: {
        padding: 30
    },
    avatar: {
        backgroundColor: '#0f80aa',
        width: 80,
        height: 80
    },
    icono: {
        fontSize: 50
    },
    form: {
        marginTop: 40,
        marginBottom: 10
    },
    gridmb: {
        margin: 0,
    },

    espacioCombo: {
        padding: 10
    },

    gridmb2: {
        paddingTop: 10
    },
    inpuText: {
        padding: 10,
    },
    link: {
        marginTop: 10,
        fontSize: "1.1rem",
        fontFamily: "Roboto",
        lineHeight: 1.5,
        color: theme.palette.primary.main,
        textDecoration: "none"
    },
    appBar: {
        paddingTop: 8,
        paddingBottom: 8
    },
    grow: {
        flexGrow: 0, //sirve para especificar el valor de crecimiento
        [theme.breakpoints.up('md')]: {
            flexGrow: 1
        }
    },
    linkAppBarLogo: {
        display: "inline-flex",
        alignItems: "center",
        color: "inherit",
        textDecoration: "none"
    },
    mr: {
        marginRight: 3,

    },
    buttonIcon: {
        fontSize: 14,
        padding: 0
    },
    linkAppBarDesktop: {
        display: "inline-flex",
        alignItems: "center",
        padding: "6px 16px",
        color: "inherit",
        textDecoration: "none"
    },
    list: {
        width: 250
    },

    listItem: {
        padding: 0
    },
    linkAppBarMovil: {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        padding: "8px 16px",
        color: "inherit",
        textDecoration: "none"
    },

    ListItemIcon: {
        minWidth: 35,
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },

    text_title: {
        fontWeight: 600,
        color: '#494949',
        marginBottom: 10,
    },

    media: {
        height: 250,
        backgroundColor: '#F2F2F2',
        margin: '15px 15px 0 15px'
    },

    price: {
        float: 'right',
        padding: '0 20px 0 20px',
        backgroundColor: '#0f8aa0',
    },

    text_card: {
        fontWeight: 'bold',
        color: '#656565',
        marginBottom: 8
    },
    paperImg: {
        backgroundColor: '#F2F2F2'
    },

    mediaDetalle: {
        width: 380,
        height: 380,
        margin: 'auto'
    },

    text_detalle: {
        fontWeight: 500,
        color: '#494949',
        marginBottom: 5
    },

    imgProductoCC: {
        backgroundColor: '#F2F2F2',
        width: 80,
        height: 70
    },

    papperPading: {
        padding: 20
    },

    divideTop: {
        marginBottom: 20
    },

    gridPC: {
        margin: "auto",
        marginTop: 20,
    },

    buttonAnterior: {
        marginRight: 8
    },
    formControl: {
        margin: 12
    },
    gridLR: {
        paddingLeft: 30,
        paddingBottom: 20,
        paddingRight: 30
    },
    divider: {
        marginTop: 12,
        marginBottom: 12
    },
    imgProductoPC: {
        backgroundColor: '#F2F2F2',
        width: 50,
        height: 40
    },

    espacioBotonesFinales: {
        paddingTop: 50
    },

    buttons: {
        backgroundColor: '#1a4821',
        color: 'white'
    },

    espacioNroSello: {
        paddingTop: 48
    },

    espacioCheckbox: {
        paddingTop: 20,
    }

}));


const EditarNuevoSello = (props) => {

    /*VAMOS A PROCEDER A PASAR DOS PARAMETROS CADA VEZ QUE ENTRAMOS AL ALTA DE SELLOS
    LA CUAL NOS PERMITIRA VALIDAR EL USUARIO Y EL PASSWORD QUE VIENE EN LA URL. PARA LO CUAL
    VAMOS A PROCEDER A CREAR EL HOOK USE PARAMS DE REACT ROUTER DOM QUE NOS PERMITIRA 
    RECUPERAR LOS DOS PARAMETROS QUE NOS ENVIA LA URL
    */
    //const {username, password} = useParams();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    let codUsuModificar = query.get('x');
    let clave = query.get('y');

    let decodeCodUsu = decodeURI(codUsuModificar);
    let decodeClave = decodeURI(clave);

    // replace() usando una expresión Regex para reemplazar espacios en blanco
    decodeCodUsu = codUsuModificar.replace(/\s+/g, '+');

    // replace() usando una expresión Regex para reemplazar espacios en blanco
    decodeClave = clave.replace(/\s+/g, '+');

    //desestructuro el objeto
    const { fncCompleted, fncClose } = props;
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnText, setBtnText] = useState('modificar');

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const FiltroSelloSellecionado = useSelector(filtroSellosSeleccionado);

    const firmanteHabilitado = !FiltroSelloSellecionado.firmanteHabilitado;

    const [frmFirmnateHabilitado, setFrmFirmanteHabilitado] = useState(new Date());
    const [firmante, setFirmante] = useState(FiltroSelloSellecionado.codUsu);
    const [funcion, setFuncion] = useState(FiltroSelloSellecionado.codFuncion);
    const [codCargo, setCodCargo] = useState(FiltroSelloSellecionado.codCargo);
    const [modalidad, setModalidad] = useState(FiltroSelloSellecionado.codModalidad);
    const [delegacion, setDelegacion] = useState(FiltroSelloSellecionado.codEnt);
    const [codActoAdministrativo, setCodActoAdministrativo] = useState(FiltroSelloSellecionado.codActoAdministrativo);
    const [nroActoAdministrativo, setNroActoAdministrativo] = useState(FiltroSelloSellecionado.nroActoAdministrativo);
    const [activoSello, setActivoSello] = useState(FiltroSelloSellecionado.activoSello);
    const [vigenciaDesde, setVigenciaDesde] = useState(new Date(FiltroSelloSellecionado.vigenciaDesde)? new Date(FiltroSelloSellecionado.vigenciaDesde): null);
    const [vigenciaHasta, setVigenciaHasta] = useState(FiltroSelloSellecionado.vigenciaHasta);
    const [descripcionCargoDigital, setDescripcionCargoDigital] = useState(FiltroSelloSellecionado.descripcionCargoDigital);
    const [descripcionCargoOlografo, setDescripcionCargoOlografo] = useState(FiltroSelloSellecionado.descripcionCargoOlografo);
    const [selloEsDigital, setSelloEsDigital] = useState(descripcionCargoDigital != null && descripcionCargoDigital != "");

    //USESELECTOR LLAMAS A LOS DATOS DE OTRO LUGAR
    //EN LA EDICION NO SE ESCOGE AL FIRMANTE PERO LO TRAIGO SI ALGUN MOMENTO LO NECESITAN, 
    //LOS DEMAS CAMPOS SI SON OBLIGATORIOS
    const FirmantesList = useSelector(firmantesList);
    const FuncionList = useSelector(funcionList);
    const CargosList = useSelector(cargosList);
    const ModalidadList = useSelector(modalidadList);
    const DelegacionList = useSelector(delegacionList);
    const ActoAdministrativoList = useSelector(actoAdministrativoList);

    //AQUI VALIDAMOS EL PROCESO DE HABILITAR Y DESHABILITAR CAMPOS CON LOS SIGUIENTES USESTATES
    const [funcionDisabled, setFuncionDisabled] = useState(false);
    const [delegacionDisabled, setDelegacionDisabled] = useState(false);
    const [actoAdministrativoDisabled, setActoAdministrativoDisabled] = useState(false);
    const [modalidadDisabled, setModalidadDisabled] = useState(false);
    const [nroActoAdministrativoDisabled, setNroActoAdministrativoDisabled] = useState(false);
    const [vigenciaDesdeDisabled, setVigenciaDesdeDisabled] = useState(false);
    const [vigenciaHastaDisabled, setVigenciaHastaDisabled] = useState(false);
    const [activoSelloDisabled, setActivoSelloDisabled] = useState(false);

    //MENSAJES DE VALIDACION DE ERRORES
    const [errorMessageFechaVigenciaDesde, setErrorMessageFechaVigenciaDesde] = useState(null);
    const [errorMessageFechaVigenciaHasta, setErrorMessageFechaVigenciaHasta] = useState(null);
    const [errorMessageModalidad, setErrorMessageModalidad] = useState(null);
    const [errorMessageActoAdministrativo, setErrorMessageActoAdministrativo] = useState(null);
    const [errorMessageNroActoAdministrativo, setErrorMessageNroActoAdministrativo] = useState(null);

    const [erroresGenerales, setErroresGenerales] = useState('');
    const [visibleError, setVisibleError] = useState(false);

    let fechaVigenciaHastaUsuarioNoHabilitadoCapturado = new Date(Date.now());

    const [swalShown, setSwalShown] = useState(false);

    const [modalVistaPreviaSello, setmodalVistaPreviaSello] = useState(false);

    const handleCloseDialog = () => {
        setmodalVistaPreviaSello(false);
    }

    const modalExitoSelloModificado = (descripcionCargoDigitalActualizada, descripcionCargoOlografoActualizada) => {
        setDescripcionCargoDigital(descripcionCargoDigitalActualizada); 
        setDescripcionCargoOlografo(descripcionCargoOlografoActualizada);
        setSelloEsDigital(descripcionCargoDigitalActualizada != null && descripcionCargoDigitalActualizada != "");
        showSwal();
    }

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
      });

    const showSwal = () => {
        swalWithBootstrapButtons.fire({
            title: 'Mensaje',
			text: "Sello Modificado!",
			icon: 'success',
			reverseButtons: true,
            didOpen: () => setSwalShown(true),
            didClose: () => {
                setSwalShown(false);
                fncCompleted(false);
            }
        })
    }

    const onChangeFuncion = (e) => {
        const opcion = e.target.value;
        localStorage.setItem("opcionAnterior", opcion); 
        setFuncion(opcion);
        //VALIDAMOS SI ESCOGEMOS EL PRIMER VALOR DE LA FUNCION
        if(opcion === 1){
            setCodCargo(1);
            setVigenciaHasta(null);
            setCodActoAdministrativo(null);
            setDelegacionDisabled(true);
            setNroActoAdministrativoDisabled(true);
            setActoAdministrativoDisabled(true);
            setVigenciaHastaDisabled(false);
            setModalidadDisabled(false);
            setErrorMessageFechaVigenciaHasta('');
            setErrorMessageActoAdministrativo('');
            setErrorMessageNroActoAdministrativo('');
            setNroActoAdministrativo('');
            setErrorMessageModalidad('');
            setModalidad('');
            if(ModalidadList === null){
                setErrorMessageModalidad('');
            }else{
                setErrorMessageModalidad('Campo Obligatorio');
            }

            let vd = moment(vigenciaDesde).format('DD/MM/YYYY');
            let vh = moment(vigenciaHasta).format('DD/MM/YYYY');

            //OBTENEMOS EL GET DE LA VARIABLE GLOBAL CREADA ANTERIORMENTE, LO CUAL NOS PERMITIRA
            //RESETAR AL VALOR ANTERIOR DEL FIRMANTE CON LA DELEGACION
            setDelegacion(localStorage.getItem("delegacionAnterior"));
            
            if(vd === ""){
                setErrorMessageFechaVigenciaDesde('Las Fechas de Vigencia Desde es Obligatoria');
            }else{
                setErrorMessageFechaVigenciaDesde('');
            }
        }

        //VALIDAMOS SI ESCOGEMOS EL SEGUNDO VALOR DE LA FUNCION
        if(opcion === 2){
            setCodCargo(2);
            setDelegacionDisabled(true);
            setNroActoAdministrativoDisabled(false);
            setActoAdministrativoDisabled(false);
            setVigenciaHastaDisabled(false);

            setModalidadDisabled(false);
            setVigenciaHasta(null);

            //VALIDACIONES FINALES
            if(modalidad === 2){
                setDelegacionDisabled(false);
            }

            if(vigenciaHasta === null){
                setErrorMessageFechaVigenciaHasta('Campo Obligatorio');
            }else{
                setErrorMessageFechaVigenciaHasta('');
            }

            if(modalidad === ""){
                setErrorMessageModalidad('Campo Obligatorio');
            }else{
                setErrorMessageModalidad('');
            }

            if(codActoAdministrativo === null){
                setErrorMessageActoAdministrativo('Campo Obligatorio');
            }else{
                setErrorMessageActoAdministrativo('');
            }

            if(nroActoAdministrativo === ""){
                setErrorMessageNroActoAdministrativo('Campo Obligatorio');
            }else{
                setErrorMessageNroActoAdministrativo('');
            }
        }

         //VALIDAMOS SI ESCOGEMOS EL TERCER VALOR DE LA FUNCION
         if(opcion === 3){
            setCodCargo(2);
            setDelegacionDisabled(false);
            setNroActoAdministrativoDisabled(false);
            setActoAdministrativoDisabled(false);
            setVigenciaHastaDisabled(false);
            setModalidadDisabled(false);
            setVigenciaHasta(null);
            setModalidad(2);
            setErrorMessageModalidad('');
            let vd = moment(vigenciaDesde);
            let vh = moment(vigenciaHasta);

            //OBTENEMOS EL GET DE LA VARIABLE GLOBAL CREADA ANTERIORMENTE, LO CUAL NOS PERMITIRA
            //RESETAR AL VALOR ANTERIOR DEL FIRMANTE CON LA DELEGACION
            setDelegacion(localStorage.getItem("delegacionAnterior"));
            //VALIDACIONES FINALES
            if(vigenciaHasta === null){
                setErrorMessageFechaVigenciaHasta('Campo Obligatorio');
            }else{
                setErrorMessageFechaVigenciaHasta('');
            }

            if(codActoAdministrativo === null){
                setErrorMessageActoAdministrativo('Campo Obligatorio');
            }else{
                setErrorMessageActoAdministrativo('');
            }

            if(nroActoAdministrativo === ""){
                setErrorMessageNroActoAdministrativo('Campo Obligatorio');
            }else{
                setErrorMessageNroActoAdministrativo('');
            }
            setModalidadDisabled(true);
         }

        //VALIDAMOS SI ESCOGEMOS EL CUARTO VALOR DE LA FUNCION
        if(opcion === 4){
            setCodCargo(3);
            setCodActoAdministrativo(null);
            setModalidad(null);
            setDelegacionDisabled(true);
            setVigenciaHastaDisabled(false);
            setActoAdministrativoDisabled(false);
            setNroActoAdministrativoDisabled(false);
            setModalidadDisabled(false);
            setVigenciaHasta(null);
            setNroActoAdministrativo('');
            //CADA VEZ QUE SE ESCOGA UNA FUNCION LOS COMBOS Y MENSAJES SE LIMPIAN
            setErrorMessageActoAdministrativo('');
            setErrorMessageNroActoAdministrativo('');
            setErrorMessageFechaVigenciaHasta('');
            
            //OBTENEMOS EL GET DE LA VARIABLE GLOBAL CREADA ANTERIORMENTE, LO CUAL NOS PERMITIRA
            //RESETAR AL VALOR ANTERIOR DEL FIRMANTE CON LA DELEGACION
            setDelegacion(localStorage.getItem("delegacionAnterior"));
            
            if(modalidad === 0 || modalidad === 1 || modalidad === 2){
                setErrorMessageModalidad('Campo Obligatorio');
            }else{
                setErrorMessageModalidad('');
            }
        }
    }

    const onChangeCodCargo = (e) => {
        setCodCargo(e.target.value);
    }

    const onChangeModalidad = (e) =>{
        setModalidad(e.target.value);
        //VALIDACION GENERAL
        if(e.target.value > 0 && funcion === ""){
            setErrorMessageModalidad('');
        }else{
        //VALIDACION DE REEMPLAZO
        if(e.target.value > 0 && funcion === 2){
            setErrorMessageModalidad('');
        }else{   
            setErrorMessageModalidad('Debe seleccionar una modalidad');
        }
    }

    //VALIDACION DE SECRETARIO TECNICO
    if(e.target.value > 0 && funcion === 1){
        setErrorMessageModalidad("");
    }else{
        setErrorMessageModalidad('Debe seleccionar una modalidad');
    }

    //VALIDACION ONCHANGE REEMPLAZO Y PRESENCIAL
    if(funcion === 2 && e.target.value === 1){
        let vd = moment(vigenciaDesde).format('DD/MM/YYYY');
        let vh = moment(vigenciaHasta).format('DD/MM/YYYY');
        setErrorMessageModalidad('');
        if(vd || vh === null){
            setDelegacionDisabled(true);
        }else{
            setDelegacionDisabled(true);
            setErrorMessageActoAdministrativo('');
            setErrorMessageNroActoAdministrativo('');
        }
    }

    //VALIDACION ONCHANGE REEMPLAZO Y REMOTA
    if(funcion === 2 && e.target.value === 2){
        let vd = moment(vigenciaDesde).format('DD/MM/YYYY');
        let vh = moment(vigenciaHasta).format('DD/MM/YYYY');
        setErrorMessageModalidad('');
        if(vh === ""){
            //setErrorMessageFechas('Las Fechas son obligatorias');
            setErrorMessageFechaVigenciaHasta('Campo Obligatorio');
            setDelegacionDisabled(false);
        }else{
            setDelegacionDisabled(false);
            //setErrorMessageFechas(null);
        }
    }

    //VALIDACION ONCHANGE AUTORIDAD
    if(funcion === 4 && e.target.value === 1 || e.target.value === 2){
        let vd = moment(vigenciaDesde).format('DD/MM/YYYY');
        let vh = moment(vigenciaHasta).format('DD/MM/YYYY');
        setErrorMessageModalidad('');
    }
    }

    const onChangeDelegacion = (e) => {
        setDelegacion(e.target.value);
    }

    const onChangeCodActoAdministrativo = (e) =>{
        setCodActoAdministrativo(e.target.value);
        if(e.target.value > 0){
            setErrorMessageActoAdministrativo('');
        }else{
            setErrorMessageActoAdministrativo('Debe escoger el acto administrativo');
        }
    }

      const onChangeNroActoAdministrativo = (e) =>{
        setNroActoAdministrativo(e.target.value);
        if(e.target.value !== ""){
            setErrorMessageNroActoAdministrativo('');
        }else{
            setErrorMessageNroActoAdministrativo('Debe escoger el numero del acto administrativo');
        }
      }

    const onChangeActivoSello = (e) => {
        setActivoSello(!activoSello);
    }

    const onChangeVigenciaDesde = (e) =>{
        let vd = moment(e);
        let vh = moment(vigenciaHasta);
        if(moment(vd).isSameOrBefore(vh) === true){
            setErrorMessageFechaVigenciaDesde('');
            setErrorMessageFechaVigenciaHasta('');
            setVisibleError(false);
        }else{
            if(vh === ""){
            }else{
                setErrorMessageFechaVigenciaHasta('');
            }
        }
        setVigenciaDesde(e);
    }

    const onChangeVigenciaHasta = (e) => {
        let vd = moment(vigenciaDesde);
        let vh = moment(e);
        setErrorMessageFechaVigenciaHasta("");
        //VALIDACION GENERAL DE LAS FECHAS DE VIGENCIA DESDE Y HASTA
        if (moment(vd).isSameOrBefore(vh)) {
          setErrorMessageFechaVigenciaDesde("");
          setErrorMessageFechaVigenciaHasta("");
        } else {
          if (vh.format("DD/MM/YYYY") === "") {
            //setErrorMessageFechaVigenciaHasta('Fecha Invalida');
          } else {
            setErrorMessageFechaVigenciaHasta("");
          }
        }
    
        if (vd !== null && e === null) {
          setErrorMessageFechaVigenciaHasta("");
        }
    
        if (funcion === 1 && moment(vd).isSameOrBefore(vh)) {
          setErrorMessageFechaVigenciaHasta("");
        } else {
          //setErrorMessageFechaVigenciaHasta('Fecha Invalida');
        }
    
        //VALIDACION DE LA VIGENCIA HASTA CUANDO ESCOGA LA FUNCION DE REEMPLAZO
        if (funcion === 2 && moment(vd).isSameOrBefore(vh)) {
          setErrorMessageFechaVigenciaHasta("");
        }

        //VALIDACION DE LA VIGENCIA HASTA CUANDO ESCOGA LA FUNCION DE APOYO
        if (funcion === 3 && moment(vd).isSameOrBefore(vh)) {
            setErrorMessageFechaVigenciaHasta("");
        }
    
        setVigenciaHasta(e);
    };
    

    useEffect(() => {
        localStorage.removeItem("opcionAnterior");
        if (FirmantesList.length === 0) {
            //llamo a la funcion del firmateSlice
            dispatch(getFirmantesAsync());
        }

        if (FuncionList.length === 0) {
            //llamo a la funcion del firmateSlice
            dispatch(getFuncionAsync());
        }

        if (ModalidadList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getModalidadAsync());
        }

        if (DelegacionList.length === 0) {
            //llamo a la delegacion del Slice
            dispatch(getDelegacionAsync());
        }

        if(funcion === 1){
                setActoAdministrativoDisabled(true);
                setNroActoAdministrativoDisabled(true);
                setDelegacionDisabled(true);
                setCodActoAdministrativo(null);
                
                setVisibleError(false);
                setVigenciaHastaDisabled(false);
                setFuncionDisabled(false);
        }

        if(funcion === 2){
            setDelegacionDisabled(true);
            setVisibleError(false);
            setCodActoAdministrativo(codActoAdministrativo);
            if(firmanteHabilitado){
                setActoAdministrativoDisabled(true);
                setNroActoAdministrativoDisabled(true);
            }


        }

        if(funcion === 3){
            setDelegacionDisabled(true);
            setVisibleError(false);
            setModalidadDisabled(true);
            setCodActoAdministrativo(codActoAdministrativo);
            if(firmanteHabilitado){
                setActoAdministrativoDisabled(true);
                setNroActoAdministrativoDisabled(true);
            } else {
                setActoAdministrativoDisabled(false);
                setNroActoAdministrativoDisabled(false);
            }    
        }

        debugger;
        if(funcion === 4){
            setDelegacionDisabled(true);
            setVisibleError(false);
            
            setCodActoAdministrativo(null);
            setNroActoAdministrativoDisabled(true);
            setActoAdministrativoDisabled(true);
            setVigenciaHastaDisabled(false);
            
            if(firmanteHabilitado){
                setCodActoAdministrativo(codActoAdministrativo);
                setActoAdministrativoDisabled(true);
                setNroActoAdministrativoDisabled(true);
            }

            if(!firmanteHabilitado){
                setCodActoAdministrativo(codActoAdministrativo);
                setActoAdministrativoDisabled(false);
                setNroActoAdministrativoDisabled(false);
                
            }   
        }
        
    }, [])

    const handleSubmitEditarSello = () => {
        setBtnDisable(true);
        setBtnText('MODIFICANDO...');
        const data = {
            idSello: FiltroSelloSellecionado.idSello,
            codUsu: firmante,
            codEnt: delegacion,
            codCargo: codCargo,
            codFuncion: funcion,
            codModalidad: modalidad,
            vigenciaDesde: vigenciaDesde,
            vigenciaHasta: vigenciaHasta,
            codActoAdministrativo: codActoAdministrativo,
            nroActoAdministrativo: nroActoAdministrativo,
            activoSello: activoSello,
            EncryptedUser: decodeCodUsu,
            EncryptedPassword: decodeClave
        }
        let vd = moment(data.vigenciaDesde).startOf('day').toDate();
        let vh = moment(data.vigenciaHasta).startOf('day').toDate();
        if(data.vigenciaDesde !== null && data.codEnt !== "" && data.codModalidad !== ""){
            //FUNCION SECRETARIO TECNICO
            if(data.codFuncion === 1){
            if(firmanteHabilitado){
                data.vigenciaHasta = fechaVigenciaHastaUsuarioNoHabilitadoCapturado;
                if(moment(vd).isSameOrBefore(vh) === true){
                    if(data.vigenciaDesde !== null && data.codEnt !== "" && data.codModalidad !== ""){
                        dispatch(putFiltroSellosAsync(data)).then((response) => {
                            if (response.payload.results) {
                                modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                            } else {
                                setErroresGenerales(`No se ha podido Actualizar!`);
                            }
                            
                            setBtnDisable(false);
                            setBtnText('MODIFICAR');
                        });
                    }else{
                        setErroresGenerales(`No cumple con guardar la función, revise si están llenos todos los campos obligatorios!`);
                        setBtnDisable(false);
                        setBtnText('MODIFICAR');
                        setVisibleError(true);
                    }
                    
                }
                
            }

                if(moment(vd).isSameOrBefore(vh) === true || data.vigenciaHasta ===  null){
                    data.vigenciaDesde = vd;
                    data.vigenciaHasta = vh;
                    if(data.vigenciaDesde !== null && data.codEnt !== "" && data.codModalidad !== ""){
                        dispatch(putFiltroSellosAsync(data)).then((response) => {
                            if (response.payload.results) {
                                modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                            } else {
                                setErroresGenerales(`No se ha podido Actualizar!`);
                            }
                            
                            setBtnDisable(false);
                            setBtnText('MODIFICAR');
                        });
                    }else{
                        setErroresGenerales(`No cumple con guardar la función, revise si están llenos todos los campos obligatorios!`);
                        setBtnDisable(false);
                        setBtnText('MODIFICAR');
                        setVisibleError(true);
                    }
                }else{
                    setErroresGenerales(`La Fecha de Vigencia Hasta siempre tiene que ser mayor a la Fecha de Vigencia Desde, revise el proceso de fechas y compárelo de forma correcta!`);
                    setBtnDisable(false);
                    setBtnText('MODIFICAR');
                    setVisibleError(true);
                }
            }

            //FUNCION REEMPLAZO
            if(data.codFuncion === 2){
                if(firmanteHabilitado){
                    if(moment(vd).isSameOrBefore(vh) === true){
                        if(data.vigenciaDesde !== null && data.vigenciaHasta !== null && data.codUsu !== "" && data.codEnt !== "" && data.codModalidad !== "" && data.codActoAdministrativo !== "" && data.nroActoAdministrativo !== ""){
                            data.vigenciaHasta = fechaVigenciaHastaUsuarioNoHabilitadoCapturado;
                            dispatch(putFiltroSellosAsync(data)).then((response) => {
                                if (response.payload.results) {
                                    modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                                } else {
                                    setErroresGenerales(`No se ha podido Actualizar!`);
                                }
                                setBtnDisable(false);
                                setBtnText('MODIFICAR');
                            });
                        }
                        
                    }
                    
                }

                if(moment(vd).isSameOrBefore(vh) === true){
                    data.vigenciaDesde = vd;
                    data.vigenciaHasta = vh;
                    if(data.vigenciaDesde !== null && data.vigenciaHasta !== null && data.codUsu !== "" && data.codEnt !== "" && data.codModalidad !== "" && data.codActoAdministrativo !== "" && data.nroActoAdministrativo !== ""){
                        dispatch(putFiltroSellosAsync(data)).then((response) => {
                            if (response.payload.results) {
                                modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                            } else {
                                setErroresGenerales(`No se ha podido Actualizar!`);
                            }
                            
                            setBtnDisable(false);
                            setBtnText('MODIFICAR');
                        });
                    }else{
                        setErroresGenerales(`No cumple con guardar la función, revise si están llenos todos los campos obligatorios!`);
                        setBtnDisable(false);
                        setBtnText('MODIFICAR');
                        setVisibleError(true);
                    }

                }else{
                    setErroresGenerales(`La Fecha de Vigencia Hasta siempre tiene que ser mayor a la Fecha de Vigencia Desde, revise el proceso de fechas y compárelo de forma correcta!`);
                    setBtnDisable(false);
                    setBtnText('MODIFICAR');
                    setVisibleError(true);
                    
                }
            }

            //FUNCION APOYO
            if(data.codFuncion === 3){
                if(firmanteHabilitado){
                    data.vigenciaHasta = fechaVigenciaHastaUsuarioNoHabilitadoCapturado;
                    if(moment(vd).isSameOrBefore(vh) === true){
                        if(data.vigenciaDesde !== null && data.vigenciaHasta !== null && data.codUsu !== "" && data.codEnt !== "" && data.codModalidad !== "" && data.codActoAdministrativo !== "" && data.nroActoAdministrativo !== ""){
                            dispatch(putFiltroSellosAsync(data)).then((response) => {
                                if (response.payload.results) {
                                    modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                                } else {
                                    setErroresGenerales(`No se ha podido Actualizar!`);
                                }
                                
                                setBtnDisable(false);
                                setBtnText('MODIFICAR');
                            });
                        }else{
                            setErroresGenerales(`No cumple con guardar la función, revise si están llenos todos los campos obligatorios!`);
                            setBtnDisable(false);
                            setBtnText('MODIFICAR');
                            setVisibleError(true);
                        }                        
                    }                    
                }

                if(moment(vd).isSameOrBefore(vh) === true){
                    data.vigenciaDesde = vd;
                    data.vigenciaHasta = vh;
                    if(data.vigenciaDesde !== null && data.vigenciaHasta !== null && data.codUsu !== "" && data.codEnt !== "" && data.codModalidad !== "" && data.codActoAdministrativo !== "" && data.nroActoAdministrativo !== ""){
                        dispatch(putFiltroSellosAsync(data)).then((response) => {
                            if (response.payload.results) {
                                modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                            } else {
                                setErroresGenerales(`No se ha podido Actualizar!`);
                            }
                            
                            setBtnDisable(false);
                            setBtnText('MODIFICAR');
                        });
                    }else{
                        setErroresGenerales(`No cumple con guardar la función, revise si están llenos todos los campos obligatorios!`);
                        setBtnDisable(false);
                        setBtnText('MODIFICAR');
                        setVisibleError(true);
                    }

                }else{
                    setErroresGenerales(`La Fecha de Vigencia Hasta ${vh} siempre tiene que ser mayor a la Fecha de Vigencia Desde ${vd}, revise el proceso de fechas y compárelo de forma correcta!`);
                    setBtnDisable(false);
                    setBtnText('MODIFICAR');
                    setVisibleError(true);
                }
            }

            //FUNCION AUTORIDAD
            if(data.codFuncion === 4){
                if(firmanteHabilitado){
                    if(vd !== ""){
                        if(data.vigenciaDesde !== null && data.codUsu !== "" && data.codEnt !== "" && data.codModalidad !== ""){
                            setCodActoAdministrativo(null);
                            dispatch(putFiltroSellosAsync(data)).then((response) => {
                                if (response.payload.results) {
                                    modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                                } else {
                                    setErroresGenerales(`No se ha podido Actualizar!`);
                                }
                                
                                setBtnDisable(false);
                                setBtnText('MODIFICAR');
                            });
                        }
                        
                    }
                    
                }
            
                if(vd !== ""){
                    data.vigenciaDesde = vd;
                    data.vigenciaHasta = vh;
                    if(data.vigenciaDesde !== null && data.codUsu !== "" && data.codEnt !== "" && data.codModalidad !== ""){
                        //setVigenciaHasta(null);
                        setCodActoAdministrativo(null);
                        dispatch(putFiltroSellosAsync(data)).then((response) => {
                            if (response.payload.results) {
                                modalExitoSelloModificado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                            } else {
                                setErroresGenerales(`No se ha podido Actualizar!`);
                            }
                            setBtnDisable(false);
                            setBtnText('MODIFICAR');
                        });
                    }else{
                        setErroresGenerales(`No cumple con guardar la función, revise si están llenos todos los campos obligatorios!`);
                        setBtnDisable(false);
                        setBtnText('MODIFICAR');
                        setVisibleError(true);
                    }

                }else{
                    setErroresGenerales(`La Fecha de Vigencia Desde no puede estar vacía, revíselo e ingreselo de forma correcta!`);
                    setBtnDisable(false);
                    setBtnText('MODIFICAR');
                    setVisibleError(true);
                }
            }

        //AQUI SE ENCUENTRA EL ELSE DE LA TERMINACION DE LA CONDICION DE FORMA GENERAL
        }else{
                setErroresGenerales(`Error, revise los campos obligatorios para modificar el sello!`);
                setBtnDisable(false);
                setBtnText('MODIFICAR');
                setVisibleError(true);
        }
    }


    return (
        <Container className={classes.containermt}>
            <Grid container justifyContent="center">
                <Typography variant="h4">Modificación de Sellos: {FiltroSelloSellecionado.firmante}</Typography>
                <Grid item lg={12} md={8}>
                    <Card className={classes.card} align="center">
                        <form onSubmit={(e) => e.preventDefault()} >
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className={classes.gridmb}>
                                    <div className={classes.datePicker}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>       
                                <DatePicker
                                        DateFnsUtils
                                        name="vigenciaDesde"
                                        inputFormat='dd/MM/yyyy'
                                        //disableFuture
                                        label="Vigencia Desde"
                                        openTo="day"
                                        disabled={firmanteHabilitado ? vigenciaDesde: vigenciaDesdeDisabled}
                                        value={vigenciaDesde}
                                        onChange={(e) => onChangeVigenciaDesde(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                        />
                                    <h6 style={{fontWeight: 'lighter', color: 'red',}}>{errorMessageFechaVigenciaDesde}</h6>
                                </LocalizationProvider>      
                                    </div>

                                </Grid>

                                <Grid item md={6} xs={12} className={classes.gridmb}>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        //disableFuture
                                        //DateFnsUtils
                                        name="vigenciaHasta"
                                        label="Vigencia Hasta"
                                        inputFormat='dd/MM/yyyy'
                                        minDate={vigenciaDesde}
                                        openTo="day"
                                        disabled={firmanteHabilitado ? vigenciaHasta: vigenciaHastaDisabled}
                                        value={vigenciaHasta}
                                        onError={(error)=> console.log({error: error}) }
                                        onChange={(e) => onChangeVigenciaHasta(e)}
                                        renderInput={(params) => <TextField {...params} />}
                                        />
                                        <h6 style={{fontWeight: 'lighter', color: 'red',}}>{errorMessageFechaVigenciaHasta}</h6>
                                </LocalizationProvider>

                                </Grid>

                                <Grid item md={5} xs={12} className={classes.gridmb}>
                                    {/* <TextField
                                        select
                                        label="Cargo"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="codCargo"
                                        value={codCargo}
                                        onChange={(e) => onChangeCodCargo(e)}
                                    >
                                        {CargosList.map((cargo, index) => (
                                            <MenuItem key={index} value={cargo.idCargo}>
                                                {cargo.descCargo}
                                            </MenuItem>
                                        ))}
                                    </TextField> */}


                                    <TextField
                                        select
                                        label="Funcion"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="funcion"
                                        disabled={firmanteHabilitado}
                                        value={funcion}
                                        onChange={(e) => onChangeFuncion(e)}
                                    >
                                        {FuncionList.map((funcion, index) => (
                                            <MenuItem key={index} value={funcion.idFuncion}>
                                                {funcion.descFuncion}
                                            </MenuItem>
                                        ))}
                                    </TextField>


                                    <TextField
                                        select
                                        label="Modalidad"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="modalidad"
                                        disabled={firmanteHabilitado ? modalidad: modalidadDisabled}
                                        value={modalidad}
                                        onChange={(e) => onChangeModalidad(e)}>
                                        {ModalidadList.map((modalidad, index) =>
                                            <MenuItem key={index} value={modalidad.idModalidad}>{modalidad.descModalidad} </MenuItem>
                                        )}
                                    </TextField>
                                    <h6 style={{fontWeight: 'lighter', color: 'red',}}>{errorMessageModalidad}</h6>
                                </Grid>

                                <Grid item md={5} xs={12} className={classes.gridmb}>
                                    <TextField
                                        select
                                        label="Delegacion"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="delegacion"
                                        disabled={delegacionDisabled}
                                        value={delegacion}
                                        onChange={(e) => onChangeDelegacion(e)}>
                                        {DelegacionList.map((delegacion, index) =>
                                            <MenuItem key={index} value={delegacion.codEnt}>{delegacion.razonSocial} </MenuItem>
                                        )}
                                    </TextField>

                                    {/*ACTO ADMINISTRATIVO*/}
                                    
                                     <TextField
                                     select
                                     label="Acto Administrativo"
                                     variant="standard"
                                     fullWidth
                                     align="left"
                                     disabled={firmanteHabilitado ? actoAdministrativoDisabled : actoAdministrativoDisabled}
                                     name="actoAdministrativo"
                                     value={firmanteHabilitado ? codActoAdministrativo: codActoAdministrativo }
                                     onChange={(e) => onChangeCodActoAdministrativo(e)}>
                                         <MenuItem value="">
                                             <em>Ninguno</em>
                                         </MenuItem>                  
                                     {ActoAdministrativoList.map((actoAdministrativo, index) => (
                                     <MenuItem key={index} value={actoAdministrativo.idActoAdministrativo}>{actoAdministrativo.descActoAdministrativo} </MenuItem>
                                     ))}
                                     
                                 </TextField>
                                 <h6 style={{fontWeight: 'lighter', color: 'red',}}>{errorMessageActoAdministrativo}</h6>
                                
                                   
                                    <FormGroup className={classes.espacioCheckbox}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={activoSello} disabled={firmanteHabilitado ? activoSello: activoSelloDisabled} color="primary" onChange={(e) => onChangeActivoSello(e)} />
                                            } label="Activo" />
                                    </FormGroup>
                                </Grid>

                                <Grid item md={2} xs={12} >

                                    <div className={classes.espacioNroSello}>
                                        <TextField
                                            label="Nro."
                                            variant="standard"
                                            fullWidth
                                            name="nroActoAdministrativo"
                                            disabled={firmanteHabilitado ? nroActoAdministrativoDisabled : nroActoAdministrativoDisabled}
                                            value={firmanteHabilitado ? nroActoAdministrativo: nroActoAdministrativo}
                                            onChange={(e) => onChangeNroActoAdministrativo(e)}
                                        />
                                        <h6 style={{fontWeight: 'lighter', color: 'red',}}>{errorMessageNroActoAdministrativo}</h6> 
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>

           
            { visibleError === true ?
            <Alert variant="outlined" severity="error">
                {erroresGenerales}
            </Alert> 
            : visibleError === false
            }
            { swalShown &&
                createPortal(
                    swalWithBootstrapButtons.getHtmlContainer() && 
                    <>
                        <br />
                        <br />
                        <br />
                        <VistaSello textoSello={selloEsDigital ? descripcionCargoDigital : descripcionCargoOlografo} esDigital={selloEsDigital} />
                    </> 
                    , swalWithBootstrapButtons.getHtmlContainer()
                )
            }

            <Dialog open={modalVistaPreviaSello} onClose={handleCloseDialog} maxWidth="md" fullWidth align="center">
              <DialogContent>
                    <VistaSello textoSello={selloEsDigital ? descripcionCargoDigital : descripcionCargoOlografo} esDigital={selloEsDigital} />
              </DialogContent>
          
            </Dialog>
  
            <Grid container spacing={2} className={classes.espacioBotonesFinales}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Button disabled={btnDisable} onClick={() => fncClose()} variant="outlined" fullWidth >
                            CANCELAR
                        </Button>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                        <Button disabled={btnDisable} onClick={() => setmodalVistaPreviaSello(true)} variant="outlined" fullWidth >
                            VER SELLO GUARDADO
                        </Button>
                </Grid>

                <Grid item lg={4} md={4} sm={4} xs={4}>
                    <Button color="success" disabled={btnDisable} onClick={() => handleSubmitEditarSello()} variant="outlined" fullWidth >
                        {btnText}
                    </Button>
                </Grid>
            </Grid>


        </Container>
    );
};

export default EditarNuevoSello;