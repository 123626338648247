import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, FormControl, FormGroup, FormControlLabel, InputLabel, Select, Container, Grid, MenuItem, Paper, Table,
  TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import 'date-fns';
import { useHistory } from 'react-router';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import TableBusquedaNroActuacion from "components/TableBusquedaNroActuacion";

const FiltroBusquedaComitente = () => {

    const useStyles = makeStyles((theme) => ({

        tituloCabecera:{
          paddingBottom: 50
        },
      
        containermt: {
          marginTop: 60,
        },
        card: {
          padding: 30,
        },
        avatar: {
          backgroundColor: "#0f80aa",
          width: 80,
          height: 80,
        },
        icono: {
          fontSize: 50,
        },
        form: {
          marginTop: 40,
          marginBottom: 10,
        },
        gridmb: {
          margin: 0,
        },
      
        espacioCombo: {
          padding: 10,
        },
      
        gridmb2: {
          paddingTop: 10,
        },
        inpuText: {
          padding: 10,
        },
        link: {
          marginTop: 10,
          fontSize: "1.1rem",
          fontFamily: "Roboto",
          lineHeight: 1.5,
          color: theme.palette.primary.main,
          textDecoration: "none",
        },
        appBar: {
          paddingTop: 8,
          paddingBottom: 8,
        },
        grow: {
          flexGrow: 0, //sirve para especificar el valor de crecimiento
          [theme.breakpoints.up("md")]: {
            flexGrow: 1,
          },
        },
        linkAppBarLogo: {
          display: "inline-flex",
          alignItems: "center",
          color: "inherit",
          textDecoration: "none",
        },
        mr: {
          marginRight: 3,
        },
        buttonIcon: {
          fontSize: 14,
          padding: 0,
        },
        linkAppBarDesktop: {
          display: "inline-flex",
          alignItems: "center",
          padding: "6px 16px",
          color: "inherit",
          textDecoration: "none",
        },
        list: {
          width: 250,
        },
      
        listItem: {
          padding: 0,
        },
        linkAppBarMovil: {
          display: "inline-flex",
          alignItems: "center",
          width: "100%",
          padding: "8px 16px",
          color: "inherit",
          textDecoration: "none",
        },
      
        ListItemIcon: {
          minWidth: 35,
        },
        sectionDesktop: {
          display: "none",
          [theme.breakpoints.up("md")]: {
            display: "flex",
          },
        },
        sectionMobile: {
          display: "flex",
          flexGrow: 1,
          [theme.breakpoints.up("md")]: {
            display: "none",
          },
        },
      
        text_title: {
          fontWeight: 600,
          color: "#494949",
          marginBottom: 10,
        },
      
        media: {
          height: 250,
          backgroundColor: "#F2F2F2",
          margin: "15px 15px 0 15px",
        },
      
        price: {
          float: "right",
          padding: "0 20px 0 20px",
          backgroundColor: "#0f8aa0",
        },
      
        text_card: {
          fontWeight: "bold",
          color: "#656565",
          marginBottom: 8,
        },
        paperImg: {
          backgroundColor: "#F2F2F2",
        },
      
        mediaDetalle: {
          width: 380,
          height: 380,
          margin: "auto",
        },
      
        text_detalle: {
          fontWeight: 500,
          color: "#494949",
          marginBottom: 5,
        },
      
        imgProductoCC: {
          backgroundColor: "#F2F2F2",
          width: 80,
          height: 70,
        },
      
        papperPading: {
          padding: 20,
        },
      
        divideTop: {
          marginBottom: 20,
        },
        gridPC: {
          margin: "auto",
          marginTop: 20,
        },
      
        buttonAnterior: {
          marginRight: 8,
        },
        formControl: {
          margin: 12,
        },
        gridLR: {
          paddingLeft: 30,
          paddingBottom: 20,
          paddingRight: 30,
        },
        divider: {
          marginTop: 12,
          marginBottom: 12,
        },
        imgProductoPC: {
          backgroundColor: "#F2F2F2",
          width: 50,
          height: 40,
        },
      
        espacioBotonesFinales: {
          paddingTop: 50,
        },
      
        espacioNro: {
          paddingTop: 62,
        },
      
        espacioMensaje: {
          padding: 10,
        },
      
        espacioCheckbox:{
          paddingTop: 25,
      },
    }));

    const dispatch = useDispatch();

    const classes = useStyles();
    const [vigenciaDesde, setVigenciaDesde] = useState(new Date());
    const [vigenciaHasta, setVigenciaHasta] = useState(null);

    //MENSAJES DE VALIDACION DE ERRORES: AQUI CREAMOS LOS USESTATE PARA MOSTRAR MENSAJES DE ERROR
    const [errorMessageFechaVigenciaDesde, setErrorMessageFechaVigenciaDesde] = useState(null);
    const [errorMessageFechaVigenciaHasta, setErrorMessageFechaVigenciaHasta] = useState(null);

    const onChangeVigenciaDesde = (e) =>{
        // let vd = moment(e);
        // let vh = moment(vigenciaHasta);

        // if(moment(vd).isSameOrBefore(vh) === true){
        //     setErrorMessageFechaVigenciaDesde('');
        //     setErrorMessageFechaVigenciaHasta('');
        // }else{
        //     if(e === null){
        //         setErrorMessageFechaVigenciaDesde('Campo Obligatorio');
        //     }else{
        //         setErrorMessageFechaVigenciaDesde('');
        //     }
        // }
        setVigenciaDesde(e);
            
      }

      const onChangeVigenciaHasta = (e) =>{
        // let vd = moment(vigenciaDesde);
        // let vh = moment(e);
        // setErrorMessageFechaVigenciaHasta('');
        // //VALIDACION GENERAL DE LAS FECHAS DE VIGENCIA DESDE Y HASTA
        // if(vd.format("DD/MM/YYYY") <= vh.format("DD/MM/YYYY")){
        //     setErrorMessageFechaVigenciaDesde('');
        //     setErrorMessageFechaVigenciaHasta('');
        // }else{
        //     if(vh.format("DD/MM/YYYY") === ""){
        //         //setErrorMessageFechaVigenciaHasta('Fecha Invalida');
        //     }else{
        //         setErrorMessageFechaVigenciaHasta('');
        //     }
        // }

        // if(vd !== null && e === null){
        //     setErrorMessageFechaVigenciaHasta('');
        // }

        // if(funcion === 1 && vd.format("DD/MM/YYYY") <= vh.format("DD/MM/YYYY")){
        //     setErrorMessageFechaVigenciaHasta('');
        // }else{
        //     //setErrorMessageFechaVigenciaHasta('Fecha Invalida');
        // }

        // //VALIDACION DE LA VIGENCIA HASTA CUANDO ESCOGA LA FUNCION DE REEMPLAZO
        // if(funcion === 2 && vd.format("DD/MM/YYYY") <= vh.format("DD/MM/YYYY")){
        //     setErrorMessageFechaVigenciaHasta('');
        // }

        setVigenciaHasta(e);
        console.log(e);
      }

      const handleEventSearch = () => {
        const data = {
        //   codUsu,
        //   codEnt,
        //   codModalidad,
        //   codFuncion,
        //   codActoAdministrativo,
        //   nroActoAdministrativo,
        //   activoSello,
        };
        //llamo a la funcion del Slice
        console.log("click");
        //dispatch(setDataSearch(data));
        //dispatch(getFiltroSellosAsync(data));   
      };

    return (
        <Container className={classes.containermt}>
          <Grid container justifyContent="center">
            <Grid item lg={12} md={8}>
              <Card className={classes.card} align="center">
                <Typography className={classes.tituloCabecera} variant="h4">BUSQUEDA ACTUACIONES</Typography>
                <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={1}>

            <Grid item md={6} xs={12} className={classes.gridmb}>
                <div className={classes.datePicker}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>       
                    <DatePicker
                            DateFnsUtils
                            name="vigenciaDesde"
                            inputFormat='dd/MM/yyyy'
                            //disableFuture
                            label="Fecha Generación Oblea Desde"
                            openTo="day"
                            value={vigenciaDesde}
                            onChange={(e) => onChangeVigenciaDesde(e)}
                            renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                            />
                        <h6 className={classes.errorMesage}>{errorMessageFechaVigenciaDesde}</h6>
                </LocalizationProvider>
        </div>
                                         
            </Grid>
            <Grid item md={6} xs={12} className={classes.gridmb}>

            <LocalizationProvider dateAdapter={AdapterDateFns}>       
                      <DatePicker
                              DateFnsUtils
                              name="vigenciaHasta"
                              inputFormat='dd/MM/yyyy'
                              //disableFuture
                              label="Fecha Generación Oblea Hasta"
                              openTo="day"
                              minDate={vigenciaDesde}
                              value={vigenciaHasta}
                              //disabled={vigenciaHastaDisabled}
                              onChange={(e) => onChangeVigenciaHasta(e)}
                              renderInput={(params) => <TextField style={{ width: '100%' }} {...params} />}
                              />
                          <h6 className={classes.errorMesage}>{errorMessageFechaVigenciaHasta}</h6>
              </LocalizationProvider>
          
              
                                       
            </Grid>

            <Grid item md={6} xs={12} className={classes.gridmb}>
                  <div className={classes.espacioNro}>
                    <TextField
                      label="Nro. Actuacion"
                      variant="standard"
                      fullWidth
                      name="nroActuacion"
                    />
                  </div>

                  
            </Grid>

            <Grid item md={6} xs={12} className={classes.gridmb}>
                  <div className={classes.espacioNro}>
                    <TextField
                      label="Nro. Legajo"
                      variant="standard"
                      fullWidth
                      name="nroLegajo"
                      type="number"
                    />
                  </div>

                  
            </Grid>
            

               
            </Grid>

            <Grid container spacing={2} className={classes.espacioBotonesFinales}>
    
            <Grid item lg={12} md={12} sm={12} xs={12}>
                 
              <Button
                color="success"
                variant="contained"
                onClick={handleEventSearch}
                fullWidth
              >
                Buscar
              </Button>
    
            </Grid>
          </Grid>
           
            </form>
              </Card>
            </Grid>
          </Grid>
    
    
          
          {/* AQUI SE ENCUENTRA EL COMPONENTE DE LA TABLA DE CONSULTA DE SELLOS - DESARROLLADO POR - ANDRES VIERA  */}
          <TableBusquedaNroActuacion />
        </Container>
      );
};

export default FiltroBusquedaComitente;