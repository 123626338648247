import { POST_SEGURIDAD, HEADERS } from 'constants/endpoints';
import axios from 'axios';
import Swal from 'sweetalert2';

export const postSeguridad = (data) => {
	const {codUsu, clave, ruta} = data;

	console.log({data: data});

	return new Promise((resolve, reject) => {
		return axios({
			url: POST_SEGURIDAD,
			method: 'POST',
			headers: HEADERS,
			data: data
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				if(json.results === false){
					Alerta.fire(
                        "Error!",
                        `${json.errorMessage}`,
                        "warning"
                      );
				}
				return resolve({ data: json });
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};
