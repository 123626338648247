
import {ROOT_OMDB} from 'constants/routes/externals.js'

export function fetchPeliculas(search) {
    return new Promise((resolve,reject) =>{
      const url = `${ROOT_OMDB}&s=${(search)?search:'true'}`;
      // console.log(url);
      return fetch(url).then(response => response.json())
      .then(data => resolve({data: data.Search})).catch((err)=>{
        reject({error: err, origen: 'peliculasAPI'})
      })
    });
}


