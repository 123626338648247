import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router";

export default function FadeMenu(props) {
  const useStyles = makeStyles((theme) => ({
    fondoMenu: {
      backgroundColor: '#1A4821',
      color: '#FFFFFF'
   },
 }));
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const altaNuevoSello = () => {
    history.push("/altaNuevoSello");
  };

  const ConsultaSellos = () => {
    history.push("/verSellos");
  };

  const ConsultaComitente = () => {
    history.push("/busquedaComitente");
  };

  const ModificacionSellos = () => {
    history.push("/modificarSellos");
  };

  return (
    
    <div className={classes.fondoMenu}>
      {/* <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Menu
      </Button> */}
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >

        <MenuItem onClick={altaNuevoSello}>Alta de Sellos</MenuItem>
        <MenuItem onClick={ModificacionSellos}>Modificación de Sellos</MenuItem>
        <MenuItem onClick={ConsultaSellos}>Consulta de Sellos</MenuItem>
        <MenuItem onClick={ConsultaComitente}>Consulta de Comitentes</MenuItem>
      </Menu>
    </div>
  );
}