export const HEADERS = {
	'Access-Control-Allow-Origin': '*',
	'Access-Control-Allow-Headers': 'Content-Type, Authorization',
	'Content-Type': "application/json; charset=utf-8",
};

let location = window.location;

export const HOST = location.hostname === 'localhost' ? process.env.REACT_APP_OMDB_KEY_LOCAL : location.hostname === 'calidadonline2' ? process.env.REACT_APP_OMDB_KEY_CALIDAD : location.hostname === 'www.cpbaonline.com.ar' ? process.env.REACT_APP_OMDB_KEY_PRODUCCION : 'No se encontro HOST';

const MS_CONTROLLER = {
	FIRMANTE: "ActuacionAutoridadFirmanteDlg/UsuariosMatriculados",
	CARGO: "CargoFirmante",
	FUNCION: "FuncionFirmante",
	MODALIDAD: "ModalidadFirmante",
	ACTO_ADMINISTRATIVO: "ActoAdministrativoFirmante",
	DELEGACION: "Entidad",
	FILTROS: "ActuacionAutoridadFirmanteDlg/SearchByFilters",
	POST_ALTANUEVOSELLO: "ActuacionAutoridadFirmanteDlg/Insert",
	PUT_ALTANUEVOSELLO: "ActuacionAutoridadFirmanteDlg/UpdateById",
	POST_SEGURIDAD: "ActuacionAutoridadFirmanteDlg/EsUsuarioValido",
	FILTRO_COMITENTE: "",
}

const ENDPOINT ={
	GET_FIRMANTE: "",
	GET_CARGO:"",
	GET_FUNCION: "",
	GET_MODALIDAD: "",
	GET_ACTO_ADMINISTRATIVO: "",
	GET_DELEGACION: ""
}
export const POST_SEGURIDAD = `${HOST}/${MS_CONTROLLER.POST_SEGURIDAD}`;
export const GET_FIRMANTES = `${HOST}/${MS_CONTROLLER.FIRMANTE}`;
export const GET_CARGO = `${HOST}/${MS_CONTROLLER.CARGO}`;
export const GET_MODALIDAD = `${HOST}/${MS_CONTROLLER.MODALIDAD}`;
export const GET_FUNCION = `${HOST}/${MS_CONTROLLER.FUNCION}`;
export const GET_ACTO_ADMINISTRATIVO = `${HOST}/${MS_CONTROLLER.ACTO_ADMINISTRATIVO}`;
export const GET_DELEGACION = `${HOST}/${MS_CONTROLLER.DELEGACION}`;
export const GET_FILTROS = `${HOST}/${MS_CONTROLLER.FILTROS}`;
export const GET_FILTRO_COMITENTE = `${HOST}/${MS_CONTROLLER.FILTRO_COMITENTE}`;
export const POST_SELLOS = `${HOST}/${MS_CONTROLLER.POST_ALTANUEVOSELLO}`;
export const PUT_SELLOS = `${HOST}/${MS_CONTROLLER.PUT_ALTANUEVOSELLO}`;