import {  Container, Grid, Typography } from '@mui/material';
import { useState } from 'react';


const VistaSello = (props) => { // tipo, nombre, titulo, area, delegacion
    const textoDividido = props.esDigital ? props.textoSello.split('|') : props.textoSello;
    const primeraLinea = props.esDigital ? textoDividido[0] : textoDividido;
    const segundaLinea = props.esDigital ? textoDividido[1] : "";
    const terceraLinea = props.esDigital ? textoDividido[2] : "";
    const cuartaLinea = props.esDigital ? textoDividido[3] : "";

    return (
        <Container> 
          <Grid container justifyContent="center">
            <Grid item>
                <Typography variant="h4">{props.esDigital ? "Sello Digital" : "Sello olografo"}</Typography>
                <Typography variant="body1">{primeraLinea}</Typography>
                <Typography variant="body1">{segundaLinea}</Typography>
                <Typography variant="body1">{terceraLinea}</Typography>
                <Typography variant="body1">{cuartaLinea}</Typography>
            </Grid>
          </Grid>
        </Container>
    );
}

export default VistaSello;