import React from 'react';
import {  makeStyles } from '@mui/styles';
import { AppBar, Avatar, Button, Card, CardContent, CardMedia, Toolbar, Typography, Image, CardActions }  from '@mui/material';
import Background from '../../scss/img/logo_cpba.png';
import FadeMenu from 'components/menu/index';



// Styles
import 'scss/components/header.scss';
import { Box } from '@mui/system';

const useStyles = makeStyles((theme) => ({
   title: {
      flexGrow: 1,
   },
   inputRoot: {
      color: 'inherit',
   },
   inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
   },

   gkPageTop:{
      backgroundImage: `URL(https://www.cpba.com.ar/images/xfondo_header.png.pagespeed.ic.jEa_pLjXEN.png)`,
      height: 100,
      backgroundColor: '#1A4821'
   },

   logoCPBA:{
      backgroundImage: `URL(https://www.cpba.com.ar/templates/gk_news/images/style1/xlogo.png.pagespeed.ic.HxJRRfhz9_.webp)`
   },

   media:{
      height: 250,
      backgroundColor: '#F2F2F2',
      margin: '15px 15px 0 15px'
  },

}));

const Header = () => {
   const classes = useStyles();

   return (
      <AppBar position="fixed">
         
         <Toolbar className={classes.gkPageTop}>

                  <Box
                     component="img"
                     alt="LOGO_CPBA"
                     src="https://www.cpba.com.ar/templates/gk_news/images/style1/xlogo.png.pagespeed.ic.HxJRRfhz9_.webp"
                     />           
         </Toolbar>
         <FadeMenu />
         
      </AppBar>
   );
};

export default Header;
