import { GET_FILTROS, POST_SELLOS, PUT_SELLOS, HEADERS } from 'constants/endpoints';
import axios from 'axios';
import Swal from 'sweetalert2';

export const getSellos = (data) => {
	const {codUsu, codFuncion, codEnt, codModalidad, codCargo, codActoAdministrativo, descActoAdministrativo, nroActoAdministrativo, activoSello, firmanteHabilitado, encryptedUser, encryptedPassword} = data;
	const vigenciaDesde = (data.vigente != "") ? (data.vigente == 'SI') ? new Date() : null : null;
	const vigenciaHasta = (data.vigente != "") ? (data.vigente == 'NO') ? new Date() : null : null;
	const res = {
		"codUsu": codUsu,
		"codEnt": codEnt,
		"codCargo": codCargo,
		"descripCargo": "",
		"saludo": "",
		"descripCargoDigital": "",
		"idSello": 0,
		"codFuncion": codFuncion,
		"codModalidad": codModalidad,
		"codActoAdministrativo": codActoAdministrativo,
		"descActoAdministrativo": descActoAdministrativo,
		"vigenciaDesde": vigenciaDesde,
		"vigenciaHasta": vigenciaHasta,
		"nroActoAdministrativo": nroActoAdministrativo,
		"activoSello": activoSello,
		"fechaInsercion": null,
		"fechaUltimaModificacion": null,
		"timeStart": "",
		"timeEnd": "",
		"firmanteHabilitado": firmanteHabilitado,
		"encryptedUser": encryptedUser,
		"encryptedPassword": encryptedPassword
	}

	return new Promise((resolve, reject) => {
		return axios({
			url: GET_FILTROS,
			method: 'GET',
			headers: HEADERS,	
			params: res	
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				if(json.dataList.length > 0){
						return resolve({ data: json.dataList });
				}else{
					Alerta.fire(
                        "Mensaje!",
                        `No hay resultados en la búsqueda`,
                        "warning"
                      );
					  return resolve({ data: json.dataList }); 
				}
				
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};


export const postSellos = (data) => {
	const {codUsu, codEnt, codCargo, codModalidad,  codFuncion, codActoAdministrativo, vigenciaDesde, vigenciaHasta, nroActoAdministrativo, activoSello, EncryptedUser, EncryptedPassword} = data;
	
	const res = {
		"codUsu": codUsu,
		"codEnt": codEnt,
		"codCargo": codCargo,
		"codFuncion": codFuncion,
		"codModalidad": codModalidad,
		"codActoAdministrativo": codActoAdministrativo,
		"vigenciaDesde": vigenciaDesde,
		"vigenciaHasta": vigenciaHasta,
		"nroActoAdministrativo": nroActoAdministrativo,
		"activoSello": activoSello,
		"EncryptedUser": EncryptedUser,
        "EncryptedPassword": EncryptedPassword
	}

	return new Promise((resolve, reject) => {
		return axios({
			url: POST_SELLOS,
			method: 'POST',
			headers: HEADERS,	
			data: res	
		})
			.then(response => response.data )
			.then(json => {
				const Alerta = Swal;
				if(json.results === true){			
					return resolve({ data: json });
				}else{
					Alerta.fire(
                        "Error!",
                        `${json.errorMessage}`,
                        "warning"
                      );
				}
				return resolve({ data: json.results });
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};

export const putSellos = (data) => {
	const {idSello, codUsu, codEnt, codCargo, codModalidad,  codFuncion, codActoAdministrativo, vigenciaDesde, vigenciaHasta, descAdministrativo, nroActoAdministrativo, activoSello, EncryptedUser, EncryptedPassword} = data;
	
	const res = {
		"codUsu": codUsu,
		"codEnt": codEnt,
		"codCargo": codCargo,
		"idSello": idSello,
		"codFuncion": codFuncion,
		"codModalidad": codModalidad,
		"codActoAdministrativo": codActoAdministrativo,
		"descAdministrativo": descAdministrativo,
		"vigenciaDesde": vigenciaDesde,
		"vigenciaHasta": vigenciaHasta,
		"nroActoAdministrativo": nroActoAdministrativo,
		"activoSello": activoSello,
		"EncryptedUser": EncryptedUser,
        "EncryptedPassword": EncryptedPassword
	}
	return new Promise((resolve, reject) => {
		return axios({
			url: PUT_SELLOS,
			method: 'PUT',
			headers: HEADERS,	
			data: res	
		})
			.then(response => response.data)
			.then(json => {
				const Alerta = Swal;
				if(json.results){
					return resolve({ data: json });
				}else{
					Alerta.fire(
                        "Mensaje!",
                        `${json.errorMessage}`,
                        "warning",
                      );
					  return resolve({ data: [] });
				}
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
	
};