import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { postSeguridad } from 'app/features/Seguridad/SeguridadAPI';

const initialState = {
    List: [],
    status: 'loading',
    authenticated: true,
  };


  // The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const postSeguridadAsync = createAsyncThunk(
    'seguridad/fetchSeguridad',
    // async (amount, { getState }) => {
    //   const state = getState();
    //   const response = await fetchFirmantes(state.firmantesReducer.search);
    //   // The value we return becomes the `fulfilled` action payload
    //   return response.data;
    // }
  
    async data => {
        const response = await postSeguridad(data);
        console.log({hola: response});
        return response.data;
    }
  );
  
  // export const postFirmanteAsync = createAsyncThunk(
  // 	'empleado/postFamiliar',
  // 	async data => {
  // 		const response = await postFamiliar(data);
  // 		return response.data;
  // 	}
  // );
  
  export const seguridadSlice = createSlice({
    name: 'seguridad',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
      // setSeguridadSeleccionado: (state, action) => {
      //   state.seguridadSeleccionado = action.payload;
      //   console.log(state.seguridadSeleccionado);
      // },
      
  
      // saveFamiliar: (state, action) => {
          // 	const familiarToSave = {
          // 		empleado_id: state.EmpleadoSelected.id,
          // 		nombre: action.payload.nombre,
          // 		parentezco: action.payload.parentezco,
          // 		id: action.payload.id,
          // 	};
          // 	if (action.payload?.id !== '') {
          // 		putFamiliarAsync(familiarToSave);
          // 	} else {
          // 		postFamiliarAsync(familiarToSave);
          // 	}
          // 	state.EmpleadoSelected.familiares[action.payload.index].saved = true;
          // 	// return a
          // },
  
      // decrement: (state) => {
      //   state.value -= 1;
      // },
      // // Use the PayloadAction type to declare the contents of `action.payload`
      // incrementByAmount: (state, action) => {
      //   state.value += action.payload;
      // },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(postSeguridadAsync.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(postSeguridadAsync.fulfilled, (state, action) => {
          state.status = 'idle';
          const {results} = action.payload;
          console.log(results);
          state.authenticated = results; 
          // console.log({action: action});
          // console.log('aqui la lista');
          // console.log(state.List);
          // state.List = action.payload;
          // console.log({estado: state.List});
        });
    },
  });
  
  //export const { setSeguridadSeleccionado } = seguridadSlice.actions;
  
  // The function below is called a selector and allows us to select a value from
  // the state. Selectors can also be defined inline where they're used instead of
  // in the slice file. For example: `useSelector((state: RootState) => state.<nameReducer>.<Key_of_state>)`
  export const seguridadList = (state) => state.seguridadReducer.List;
  export const seguridadStatus = state => state.seguridadReducer.status;
  export const seguridadAuthenticated = state => state.seguridadReducer.authenticated;
  //export const seguridadSeleccionado = (state) => state.seguridadReducer.seguridadSeleccionado;
  
  // We can also write thunks by hand, which may contain both sync and async logic.
  // Here's an example of conditionally dispatching actions based on current state.
  // export const incrementIfOdd = (amount) => (dispatch, getState) => {
  //   const currentValue = selectCount(getState());
  //   if (currentValue % 2 === 1) {
  //     dispatch(incrementByAmount(amount));
  //   }
  // };
  
  export default seguridadSlice.reducer;