import { GET_FIRMANTES, HEADERS } from 'constants/endpoints';
import axios from 'axios';

export const getFirmante = () => {
	return new Promise((resolve, reject) => {
		const todos = 0;//consulta y modificacion
		const res={
			"busqueda": todos,
		}
		return axios({
			url: GET_FIRMANTES,
			method: 'GET',
			headers: HEADERS,
			params: res
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
};

