import { Alert, AlertTitle, Avatar, Button, Card, Checkbox, FormControl, FormGroup, FormControlLabel, InputLabel, Select, CardContent, CardMedia, Container, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, OutlinedInput } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import 'date-fns';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { filtroSellosSeleccionado } from 'app/features/Filtros/filtroSellosSlice';
import { firmantesList  } from 'app/features/Firmante/firmanteSlice';
import { modalidadList, getModalidadAsync } from 'app/features/Modalidad/ModalidadSlice';
import { delegacionList, getDelegacionAsync } from 'app/features/Delegacion/DelegacionSlice';
import { funcionList, getFuncionAsync } from 'app/features/Funcion/FuncionSlice';
import { actoAdministrativoList } from 'app/features/ActoAdministrativo/ActoAdministrativoSlice';
import { cargosList } from "app/features/Cargo/cargoSlice";

import VistaSello from 'components/vistaSello';

const useStyles = makeStyles((theme) => ({
    containermt: {
        marginTop: 60
    },
    card: {
        padding: 30
    },
    avatar: {
        backgroundColor: '#0f80aa',
        width: 80,
        height: 80
    },
    icono: {
        fontSize: 50
    },
    form: {
        marginTop: 40,
        marginBottom: 10
    },
    gridmb: {
        margin: 0,
    },

    espacioCombo: {
        padding: 10
    },

    gridmb2: {
        paddingTop: 10
    },
    inpuText: {
        padding: 10,
    },
    link: {
        marginTop: 10,
        fontSize: "1.1rem",
        fontFamily: "Roboto",
        lineHeight: 1.5,
        color: theme.palette.primary.main,
        textDecoration: "none"
    },
    appBar: {
        paddingTop: 8,
        paddingBottom: 8
    },
    grow: {
        flexGrow: 0, //sirve para especificar el valor de crecimiento
        [theme.breakpoints.up('md')]: {
            flexGrow: 1
        }
    },
    linkAppBarLogo: {
        display: "inline-flex",
        alignItems: "center",
        color: "inherit",
        textDecoration: "none"
    },
    mr: {
        marginRight: 3,

    },
    buttonIcon: {
        fontSize: 14,
        padding: 0
    },
    linkAppBarDesktop: {
        display: "inline-flex",
        alignItems: "center",
        padding: "6px 16px",
        color: "inherit",
        textDecoration: "none"
    },
    list: {
        width: 250
    },

    listItem: {
        padding: 0
    },
    linkAppBarMovil: {
        display: "inline-flex",
        alignItems: "center",
        width: "100%",
        padding: "8px 16px",
        color: "inherit",
        textDecoration: "none"
    },

    ListItemIcon: {
        minWidth: 35,
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    sectionMobile: {
        display: 'flex',
        flexGrow: 1,
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },

    text_title: {
        fontWeight: 600,
        color: '#494949',
        marginBottom: 10,
    },

    media: {
        height: 250,
        backgroundColor: '#F2F2F2',
        margin: '15px 15px 0 15px'
    },

    price: {
        float: 'right',
        padding: '0 20px 0 20px',
        backgroundColor: '#0f8aa0',
    },

    text_card: {
        fontWeight: 'bold',
        color: '#656565',
        marginBottom: 8
    },
    paperImg: {
        backgroundColor: '#F2F2F2'
    },

    mediaDetalle: {
        width: 380,
        height: 380,
        margin: 'auto'
    },

    text_detalle: {
        fontWeight: 500,
        color: '#494949',
        marginBottom: 5
    },

    imgProductoCC: {
        backgroundColor: '#F2F2F2',
        width: 80,
        height: 70
    },

    papperPading: {
        padding: 20
    },

    divideTop: {
        marginBottom: 20
    },

    gridPC: {
        margin: "auto",
        marginTop: 20,
    },

    buttonAnterior: {
        marginRight: 8
    },
    formControl: {
        margin: 12
    },
    gridLR: {
        paddingLeft: 30,
        paddingBottom: 20,
        paddingRight: 30
    },
    divider: {
        marginTop: 12,
        marginBottom: 12
    },
    imgProductoPC: {
        backgroundColor: '#F2F2F2',
        width: 50,
        height: 40
    },

    espacioBotonesFinales: {
        paddingTop: 50
    },

    buttons: {
        backgroundColor: '#1a4821',
        color: 'white'
    },

    espacioNroSello: {
        paddingTop: 48
    },

    espacioCheckbox: {
        paddingTop: 20,
    }

}));


const VerDetalleSello = (props) => {

    //desestructuro el objeto
    const { fncCompleted, fncClose } = props;
    const [btnDisable, setBtnDisable] = useState(false);
    const [btnText, setBtnText] = useState('modificar');

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const FiltroSelloSellecionado = useSelector(filtroSellosSeleccionado);

    const [funcion, setFuncion] = useState(FiltroSelloSellecionado.codFuncion);
    const [codCargo, setCodCargo] = useState(FiltroSelloSellecionado.codCargo);
    const [modalidad, setModalidad] = useState(FiltroSelloSellecionado.codModalidad);
    const [delegacion, setDelegacion] = useState(FiltroSelloSellecionado.codEnt);
    const [codActoAdministrativo, setCodActoAdministrativo] = useState(FiltroSelloSellecionado.codActoAdministrativo);
    const [nroActoAdministrativo, setNroActoAdministrativo] = useState(FiltroSelloSellecionado.nroActoAdministrativo);
    const [activoSello, setActivoSello] = useState(FiltroSelloSellecionado.activoSello);
    const [vigenciaDesde, setVigenciaDesde] = useState(new Date(FiltroSelloSellecionado.vigenciaDesde)? new Date(FiltroSelloSellecionado.vigenciaDesde): null);
    const [vigenciaHasta, setVigenciaHasta] = useState(FiltroSelloSellecionado.vigenciaHasta);
    const descripcionCargoDigital = FiltroSelloSellecionado.descripcionCargoDigital;
    const descripcionCargoOlografo = FiltroSelloSellecionado.descripcionCargoOlografo;
    const selloEsDigital = descripcionCargoDigital != null && descripcionCargoDigital != "" ;

    //USESELECTOR LLAMAS A LOS DATOS DE OTRO LUGAR
    //EN LA EDICION NO SE ESCOGE AL FIRMANTE PERO LO TRAIGO SI ALGUN MOMENTO LO NECESITAN, 
    //LOS DEMAS CAMPOS SI SON OBLIGATORIOS
    const FirmantesList = useSelector(firmantesList);
    const FuncionList = useSelector(funcionList);
    const CargosList = useSelector(cargosList);
    const ModalidadList = useSelector(modalidadList);
    const DelegacionList = useSelector(delegacionList);
    const ActoAdministrativoList = useSelector(actoAdministrativoList);

    //AQUI VALIDAMOS EL PROCESO DE HABILITAR Y DESHABILITAR CAMPOS CON LOS SIGUIENTES USESTATES
    const [delegacionDisabled, setDelegacionDisabled] = useState(false);
    const [actoAdministrativoDisabled, setActoAdministrativoDisabled] = useState(false);
    const [funcionDisabled, setFuncionDisabled] = useState(false);
    const [modalidadDisabled, setModalidadDisabled] = useState(false);
    const [nroActoAdministrativoDisabled, setNroActoAdministrativoDisabled] = useState(false);
    const [vigenciaDesdeDisabled, setVigenciaDesdeDisabled] = useState(false);
    const [vigenciaHastaDisabled, setVigenciaHastaDisabled] = useState(false);
    const [activoDisabled, setActivoDisabled] = useState(false);
    const [modalVistaPreviaSello, setmodalVistaPreviaSello] = useState(false);

    const handleClose = () => {
        setmodalVistaPreviaSello(false);
    }

    useEffect(() => {
        localStorage.removeItem("opcionAnterior");
        if (FuncionList.length === 0) {
            //llamo a la funcion del firmateSlice
            dispatch(getFuncionAsync());
        }

        if (ModalidadList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getModalidadAsync());
        }

        if (DelegacionList.length === 0) {
            //llamo a la funcion del Slice
            dispatch(getDelegacionAsync());
        }

        if(funcion === 1){
            setVigenciaDesdeDisabled(true);
            setVigenciaHastaDisabled(true);
            setFuncionDisabled(true);
            setModalidadDisabled(true);
            setActoAdministrativoDisabled(true);
            setNroActoAdministrativoDisabled(true);
            setDelegacionDisabled(true);
            setActivoDisabled(true);
          
           
        }

        if(funcion === 2){
            setVigenciaDesdeDisabled(true);
            setVigenciaHastaDisabled(true);
            setFuncionDisabled(true);
            setModalidadDisabled(true);
            setActoAdministrativoDisabled(true);
            setNroActoAdministrativoDisabled(true);
            setDelegacionDisabled(true);
            setActivoDisabled(true);
           
        }

        if(funcion === 3){
            setVigenciaDesdeDisabled(true);
            setVigenciaHastaDisabled(true);
            setFuncionDisabled(true);
            setModalidadDisabled(true);
            setActoAdministrativoDisabled(true);
            setNroActoAdministrativoDisabled(true);
            setDelegacionDisabled(true);
            setActivoDisabled(true);
           
        }

        if(funcion === 4){
            setVigenciaDesdeDisabled(true);
            setVigenciaHastaDisabled(true);
            setFuncionDisabled(true);
            setModalidadDisabled(true);
            setActoAdministrativoDisabled(true);
            setNroActoAdministrativoDisabled(true);
            setDelegacionDisabled(true);
            setActivoDisabled(true);
            
        }
        
    }, [])

    return (
        <Container className={classes.containermt}>
            <Grid container justifyContent="center">
                <Typography variant="h4">Consulta de sello firmante: {FiltroSelloSellecionado.firmante}</Typography>
                <Grid item lg={12} md={8}>
                    <Card className={classes.card} align="center">
                        <form onSubmit={(e) => e.preventDefault()} >
                            <Grid container spacing={2}>
                                <Grid item md={6} xs={12} className={classes.gridmb}>
                                    <div className={classes.datePicker}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>       
                                <DatePicker
                                        DateFnsUtils
                                        name="vigenciaDesde"
                                        inputFormat='dd/MM/yyyy'
                                        //disableFuture
                                        label="Vigencia Desde"
                                        openTo="day"
                                        disabled={vigenciaDesdeDisabled}
                                        value={vigenciaDesde}
                                        renderInput={(params) => <TextField {...params} />}
                                        />
                                   
                                </LocalizationProvider>      
                                    </div>

                                </Grid>

                                <Grid item md={6} xs={12} className={classes.gridmb}>

                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        //disableFuture
                                        //DateFnsUtils
                                        name="vigenciaHasta"
                                        label="Vigencia Hasta"
                                        inputFormat='dd/MM/yyyy'
                                        minDate={vigenciaDesde}
                                        openTo="day"
                                        disabled={vigenciaHastaDisabled}
                                        value={vigenciaHasta}
                                        onError={(error)=> console.log({error: error}) }
                                       
                                        renderInput={(params) => <TextField {...params} />}
                                        />
                                        
                                </LocalizationProvider>

                                </Grid>

                                <Grid item md={5} xs={12} className={classes.gridmb}>

                                    <TextField
                                        select
                                        label="Funcion"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="funcion"
                                        disabled={funcionDisabled}
                                        value={funcion}
                                    >
                                        {FuncionList.map((funcion, index) => (
                                            <MenuItem key={index} value={funcion.idFuncion}>
                                                {funcion.descFuncion}
                                            </MenuItem>
                                        ))}
                                    </TextField>


                                    <TextField
                                        select
                                        label="Modalidad"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="modalidad"
                                        disabled={modalidadDisabled}
                                        value={modalidad}>
                                        {ModalidadList.map((modalidad, index) =>
                                            <MenuItem key={index} value={modalidad.idModalidad}>{modalidad.descModalidad} </MenuItem>
                                        )}
                                    </TextField>
                                    
                                </Grid>

                                <Grid item md={5} xs={12} className={classes.gridmb}>
                                    <TextField
                                        select
                                        label="Delegacion"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        name="delegacion"
                                        disabled={delegacionDisabled}
                                        value={delegacion}>
                                        {DelegacionList.map((delegacion, index) =>
                                            <MenuItem key={index} value={delegacion.codEnt}>{delegacion.razonSocial} </MenuItem>
                                        )}
                                    </TextField>

                                    {/*ACTO ADMINISTRATIVO*/}
                                    <TextField
                                        select
                                        label="Acto Administrativo"
                                        variant="standard"
                                        fullWidth
                                        align="left"
                                        disabled={actoAdministrativoDisabled}
                                        name="actoAdministrativo"
                                        value={codActoAdministrativo}>
                                            <MenuItem value="">
                                                <em>Ninguno</em>
                                            </MenuItem>                  
                                        {ActoAdministrativoList.map((actoAdministrativo, index) => (
                                        <MenuItem key={index} value={actoAdministrativo.idActoAdministrativo}>{actoAdministrativo.descActoAdministrativo} </MenuItem>
                                        ))}
                                        
                                    </TextField>
                                    
                                    <FormGroup className={classes.espacioCheckbox}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox disabled={activoDisabled} checked={activoSello} color="primary" />
                                            } label="Activo" />
                                    </FormGroup>
                                </Grid>

                                <Grid item md={2} xs={12} >

                                    <div className={classes.espacioNroSello}>
                                        <TextField
                                            label="Nro."
                                            variant="standard"
                                            fullWidth
                                            name="numero"
                                            disabled={nroActoAdministrativoDisabled}
                                            value={nroActoAdministrativo}  
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Card>
                </Grid>
            </Grid>
            <Dialog open={modalVistaPreviaSello} onClose={handleClose} maxWidth="md" fullWidth align="center">
              <DialogContent>
                    <VistaSello textoSello={selloEsDigital ? descripcionCargoDigital : descripcionCargoOlografo} esDigital={selloEsDigital} />
              </DialogContent>
          
            </Dialog>

            <Grid container spacing={2} className={classes.espacioBotonesFinales}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button disabled={btnDisable} onClick={() => setmodalVistaPreviaSello(true)} variant="outlined" fullWidth >
                        VER SELLO GUARDADO
                    </Button>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Button disabled={btnDisable} onClick={() => fncClose()} variant="outlined" fullWidth >
                        CERRAR
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};

export default VerDetalleSello;