import { GET_FIRMANTES, HEADERS } from 'constants/endpoints';
import axios from 'axios';

export const getFirmanteAlta = () => {
	return new Promise((resolve, reject) => {
		const activo=1; // alta
		return axios({
			url: GET_FIRMANTES,
			method: 'GET',
			headers: HEADERS,
			params: {
				"busqueda": activo,
			},
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
};

