import {
  Button,
  Card,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createPortal } from 'react-dom'
import { makeStyles } from "@mui/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "date-fns";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  postSeguridadAsync,
  seguridadAuthenticated,
  seguridadStatus,
} from "app/features/Seguridad/SeguridadSlice";
import {
  getFiltroSellosAsync,
  postFiltroSellosAsync,
} from "app/features/Filtros/filtroSellosSlice";
import {
  firmantesAltaList,
  getFirmantesAltaAsync,
} from "app/features/FirmanteAlta/firmanteAltaSlice";
import {
  modalidadList,
  getModalidadAsync,
} from "app/features/Modalidad/ModalidadSlice";
import {
  delegacionList,
  getDelegacionAsync,
} from "app/features/Delegacion/DelegacionSlice";
import {
  funcionList,
  getFuncionAsync,
} from "app/features/Funcion/FuncionSlice";
import {
  actoAdministrativoList,
  getActoAdministrativoAsync,
} from "app/features/ActoAdministrativo/ActoAdministrativoSlice";
import { cargosList, getCargoAsync } from "app/features/Cargo/cargoSlice";
import Swal from "sweetalert2";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../constants/routes/internals";
import { TipoFuncionAdministrativaFactory } from "app/features/Utils/index"
import VistaSello from 'components/vistaSello';

const useStyles = makeStyles((theme) => ({
  containermt: {
    marginTop: 60,
  },
  card: {
    padding: 30,
  },

  tituloCabecera: {
    paddingBottom: 50,
  },

  gridmb: {
    margin: 0,
  },

  espacioBotonesFinales: {
    paddingTop: 50,
  },

  espacioNroSello: {
    paddingTop: 48,
  },

  espacioCheckbox: {
    paddingTop: 10,
  },

  ocultarCodCargo: {
    visibility: "hidden",
  },

  errorMesage: {
    fontWeight: "lighter",
    color: "red",
  },

  botonHidden: {
    visibility: "hidden",
  },
}));

const AltaNuevoSello = (props) => {
  //TRAEMOS LAS RUTAS PARA SER ENVIADAS AL BACKEND
  const rutaPermiso = ROUTES.ALTANUEVOSELLO.CLASS_NAME;

  /*VAMOS A PROCEDER A PASAR DOS PARAMETROS CADA VEZ QUE ENTRAMOS AL ALTA DE SELLOS
    LA CUAL NOS PERMITIRA VALIDAR EL USUARIO Y EL PASSWORD QUE VIENE EN LA URL. PARA LO CUAL
    VAMOS A PROCEDER A CREAR EL HOOK USE PARAMS DE REACT ROUTER DOM QUE NOS PERMITIRA 
    RECUPERAR LOS DOS PARAMETROS QUE NOS ENVIA LA URL
    */
  //const {username, password} = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let codUsu = query.get("x");
  let clave = query.get("y");
  //let ruta = query.get('rutaPermiso');

  let decodeCodUsu = decodeURI(codUsu);
  let decodeClave = decodeURI(clave);

  // replace() usando una expresión Regex para reemplazar espacios en blanco
  decodeCodUsu = codUsu.replace(/\s+/g, "+");

  // replace() usando una expresión Regex para reemplazar espacios en blanco
  decodeClave = clave.replace(/\s+/g, "+");

  /*CREAMOS LAS CONSTANTES PARA SU USO*/
  //ES UN HOOK QUE DETERMINA LOS ESTILOS QUE SE VA A UTILIZAR EN ESTE FORMULARIO
  const classes = useStyles();
  //ES UN HOOK QUE ME PERMITE PASAR DE UN FORMULARIO A OTRO COMO UNA RUTA
  const history = useHistory();
  //ESTA UN HOOK QUE ME PERMITE ENVIAR UNA ACCION LA CUAL DESENCADENA UN CAMBIO DE ESTADO
  const dispatch = useDispatch();

  const Authenticated = useSelector(seguridadAuthenticated);
  const status = useSelector(seguridadStatus);

  /*USESTATE NOS PERMITE ALMACENAR EL ESTADO DE UN COMPONENTE: EL SET ACTUALIZA EL ESTADO DEL COMPONENTE*/
  const [firmante, setFirmante] = useState("");
  const [funcion, setFuncion] = useState("");
  const [modalidad, setModalidad] = useState("");
  const [delegacion, setDelegacion] = useState("");
  const [codCargo, setCodCargo] = useState("");
  const [codActoAdministrativo, setCodActoAdministrativo] = useState("");
  const [nroActoAdministrativo, setNroActoAdministrativo] = useState("");
  const [activoSello, setActivoSello] = useState(true);
  const [vigenciaDesde, setVigenciaDesde] = useState(new Date());
  const [vigenciaHasta, setVigenciaHasta] = useState(null);
  const [descripcionCargoDigital, setDescripcionCargoDigital] = useState("");
  const [descripcionCargoOlografo, setDescripcionCargoOlografo] = useState("");
  const [selloEsDigital, setSelloEsDigital] = useState(descripcionCargoDigital != null && descripcionCargoDigital != "");

  //AQUI VALIDAMOS EL PROCESO DE HABILITAR Y DESHABILITAR CAMPOS CON LOS SIGUIENTES USESTATES
  const [delegacionDisabled, setDelegacionDisabled] = useState(false);
  const [actoAdministrativoDisabled, setActoAdministrativoDisabled] =
    useState(false);
  const [modalidadDisabled, setModalidadDisabled] = useState(false);
  const [nroActoAdministrativoDisabled, setNroActoAdministrativoDisabled] =
    useState(false);
  const [vigenciaHastaDisabled, setVigenciaHastaDisabled] = useState(false);

  //MENSAJES DE VALIDACION DE ERRORES: AQUI CREAMOS LOS USESTATE PARA MOSTRAR MENSAJES DE ERROR
  const [errorMessageFechaVigenciaDesde, setErrorMessageFechaVigenciaDesde] =
    useState(null);
  const [errorMessageFechaVigenciaHasta, setErrorMessageFechaVigenciaHasta] =
    useState(null);
  const [errorMessageModalidad, setErrorMessageModalidad] = useState(null);
  const [errorMessageActoAdministrativo, setErrorMessageActoAdministrativo] =
    useState(null);
  const [
    errorMessageNroActoAdministrativo,
    setErrorMessageNroActoAdministrativo,
  ] = useState(null);
  const [swalShown, setSwalShown] = useState(false);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  });


  const showSwal = () => {
    swalWithBootstrapButtons.fire({
      title: 'Sello Guardado!',
      text: "El Sello se ha guardado en el sistema!",
      icon: 'success',
      reverseButtons: true,
      didOpen: () => setSwalShown(true),
      didClose: () => {
        setSwalShown(false);
      }
    })
  }

  const modalExitoSelloCreado = (descripcionCargoDigitalActualizada, descripcionCargoOlografoActualizada) => {
    setDescripcionCargoDigital(descripcionCargoDigitalActualizada); 
    setDescripcionCargoOlografo(descripcionCargoOlografoActualizada);
    setSelloEsDigital(descripcionCargoDigitalActualizada != null && descripcionCargoDigitalActualizada != "");
    showSwal();
  }

  /*
     USESELECTOR LLAMAS A LOS DATOS DE OTRO LUGAR: CREAMOS USESELECTOR PARA LLAMAR A LOS DATOS Y DESPUES 
     RECORRERLOS CON EL MAP Y MOSTRAR SU LISTA
     */
  const FirmantesAltaList = useSelector(firmantesAltaList);
  const FuncionList = useSelector(funcionList);
  const CargosList = useSelector(cargosList);
  const ModalidadList = useSelector(modalidadList);
  const DelegacionList = useSelector(delegacionList);
  const ActoAdministrativoList = useSelector(actoAdministrativoList);

  const onChangeFirmante = (e, value) => {
    if (value !== null) {
      const opcion = value.value;
      localStorage.setItem("opcionAnterior", opcion);
      setFirmante(opcion);
      setDelegacion(firmantePersonalizado(opcion));
      localStorage.setItem("opcionAnterior", opcion);
    } else {
      setFirmante("");
      setDelegacion("");
    }

    /*
        AGREGAMOS LOCAL STORAGE PARA PASAR ESTA VARIABLE DE MANERA GLOBAL
        YA QUE NECESITAREMOS PARA RECUPERAR EL VALUE DE LA DELEGACION 
        Y CUANDO PASE A OTRA FUNCION RESETEE SU ESTADO ANTERIOR CON EL FIRMANTE
        ESPECIALMENTE SE VE ESTE PASO CUANDO SE PASA DE SECRETARIO TECNICO A REEMPLAZO
        Desarrollado por - Andres Viera
        */
  };
  //FUNCION PARA LLAMAR AL INDICE DEL FIRMANTE POR EL CODUSU Y TRAER A LA DELEGACION
  const firmantePersonalizado = (value) => {
    const indice = FirmantesAltaList.findIndex((obj) => obj.codUsu === value);
    localStorage.setItem(
      "delegacionAnterior",
      FirmantesAltaList[indice].codEnt
    );
    return FirmantesAltaList[indice].codEnt;
  };

  /*
    EN ESTE MÉTODO ONCHANGE REALIZAMOS OPERACIONES LA CUALES NOS PERMITIRÁN VER SI ESCOGEMOS UNA FUNCIÓN
    SE PUEDA HABILITAR O SE DESHABILITAR CIERTOS COMPONENTES DEL RENDER, POR EJEMPLO HE CREADO UNA CONSTANTE OPCION,
    LA CUAL ME PERMITIRÁ RECUPERAR EL VALOR DEL COMBO FUNCIÓN, SEGUIDO LLAMO AL USESTATE (SETFUNCION) QUE ME VA
    PERMITIR ALMACENAR ESE VALOR, POR CONSIGUIENTE EMPEZAMOS UN PROCESO DE VALIDACION.
    Desarrollado por - Andres Viera
    */
  const onChangeFuncionCargo = (e) => {
    const opcion = e.target.value;
    setFuncion(opcion);
    //VALIDAMOS SI ESCOGEMOS EL PRIMER VALOR DE LA FUNCION SECRETARIO TECNICO
    if (opcion === 1) {
      setCodCargo(1);
      setCodActoAdministrativo(null);
      setDelegacionDisabled(true);
      setNroActoAdministrativoDisabled(true);
      setActoAdministrativoDisabled(true);
      setVigenciaHastaDisabled(false);
      setModalidadDisabled(false);
      setErrorMessageFechaVigenciaHasta("");
      setErrorMessageActoAdministrativo("");
      setErrorMessageNroActoAdministrativo("");
      setNroActoAdministrativo("");

      if (modalidad === "") {
        setErrorMessageModalidad("Campo Obligatorio");
      } else {
        setErrorMessageModalidad("");
      }

      let vd = moment(vigenciaDesde).format("DD/MM/YYYY");
      let vh = moment(vigenciaHasta).format("DD/MM/YYYY");

      //OBTENEMOS EL GET DE LA VARIABLE GLOBAL CREADA ANTERIORMENTE, LO CUAL NOS PERMITIRA
      //RESETAR AL VALOR ANTERIOR DEL FIRMANTE CON LA DELEGACION
      setDelegacion(localStorage.getItem("delegacionAnterior"));

      if (vd === "") {
        setErrorMessageFechaVigenciaDesde(
          "Las Fechas de Vigencia Desde es Obligatoria"
        );
      } else {
        setErrorMessageFechaVigenciaDesde("");
      }
    }
    //VALIDAMOS SI ESCOGEMOS EL SEGUNDO VALOR DE LA FUNCION REEMPLAZO
    if (opcion === 2) {
      setCodCargo(2);
      setDelegacionDisabled(true);
      setNroActoAdministrativoDisabled(false);
      setActoAdministrativoDisabled(false);
      setVigenciaHastaDisabled(false);
      setModalidadDisabled(false);

      //VALIDACIONES FINALES
      if (modalidad === 2) {
        setDelegacionDisabled(false);
      }

      if (vigenciaHasta === null) {
        setErrorMessageFechaVigenciaHasta("Campo Obligatorio");
      } else {
        setErrorMessageFechaVigenciaHasta("");
      }

      if (modalidad === "") {
        setErrorMessageModalidad("Campo Obligatorio");
      } else {
        setErrorMessageModalidad("");
      }

      if (codActoAdministrativo === null) {
        setErrorMessageActoAdministrativo("Campo Obligatorio");
      } else {
        setErrorMessageActoAdministrativo("");
      }

      if (nroActoAdministrativo === "") {
        setErrorMessageNroActoAdministrativo("Campo Obligatorio");
      } else {
        setErrorMessageNroActoAdministrativo("");
      }
    }

    //VALIDAMOS SI ESCOGEMOS EL TERCER VALOR DE LA FUNCION APOYO
    if (opcion === 3) {
      setCodCargo(2);
      dispatch(getDelegacionAsync());
      setDelegacionDisabled(false);
      setNroActoAdministrativoDisabled(false);
      setActoAdministrativoDisabled(false);
      setVigenciaHastaDisabled(false);
      setModalidadDisabled(true);
      setModalidad(2);
      setErrorMessageModalidad("");

      //OBTENEMOS EL GET DE LA VARIABLE GLOBAL CREADA ANTERIORMENTE, LO CUAL NOS PERMITIRA
      //RESETAR AL VALOR ANTERIOR DEL FIRMANTE CON LA DELEGACION
      setDelegacion(localStorage.getItem("delegacionAnterior"));

      //VALIDACIONES FINALES
      if (vigenciaHasta === null) {
        setErrorMessageFechaVigenciaHasta("Campo Obligatorio");
      } else {
        setErrorMessageFechaVigenciaHasta("");
      }

      if (codActoAdministrativo === null) {
        setErrorMessageActoAdministrativo("Campo Obligatorio");
      } else {
        setErrorMessageActoAdministrativo("");
      }

      if (nroActoAdministrativo === "") {
        setErrorMessageNroActoAdministrativo("Campo Obligatorio");
      } else {
        setErrorMessageNroActoAdministrativo("");
      }
    }

    //VALIDAMOS SI ESCOGEMOS EL CUARTO VALOR DE LA FUNCION AUTORIDAD
    if (opcion === 4) {
      setCodCargo(3);
      setDelegacionDisabled(true);
      setCodActoAdministrativo(null);
      setVigenciaHastaDisabled(false);
      setActoAdministrativoDisabled(false);
      setNroActoAdministrativoDisabled(false);
      setModalidadDisabled(false);
      //setVigenciaHasta(null);
      //CADA VEZ QUE SE ESCOGA UNA FUNCION LOS COMBOS Y MENSAJES SE LIMPIAN
      setErrorMessageFechaVigenciaHasta("");
      setErrorMessageActoAdministrativo("");
      setErrorMessageNroActoAdministrativo("");

      //OBTENEMOS EL GET DE LA VARIABLE GLOBAL CREADA ANTERIORMENTE, LO CUAL NOS PERMITIRA
      //SETEAR EL VALOR ANTERIOR DEL FIRMANTE CON LA DELEGACION
      setDelegacion(localStorage.getItem("delegacionAnterior"));

      if (modalidad === "") {
        setErrorMessageModalidad("Campo Obligatorio");
      } else {
        setErrorMessageModalidad("");
      }

      // if(codActoAdministrativo === null){
      //     setErrorMessageActoAdministrativo('Campo Obligatorio');
      // }else{
      //     setErrorMessageActoAdministrativo('');
      // }

      // if(nroActoAdministrativo === ""){
      //     setErrorMessageNroActoAdministrativo('Campo Obligatorio');
      // }else{
      //     setErrorMessageNroActoAdministrativo('');
      // }
    }
  };

  const onChangeModalidad = (e) => {
    setModalidad(e.target.value);
    //VALIDACION GENERAL
    if (e.target.value > 0 && funcion === "") {
      setErrorMessageModalidad("");
    } else {
      //VALIDACION DE REEMPLAZO
      if (e.target.value > 0 && funcion === 2) {
        setErrorMessageModalidad("");
      } else {
        setErrorMessageModalidad("Debe seleccionar una modalidad");
      }
    }

    //VALIDACION DE SECRETARIO TECNICO
    if (e.target.value > 0 && funcion === 1) {
      setErrorMessageModalidad("");
    } else {
      setErrorMessageModalidad("Debe seleccionar una modalidad");
    }

    //VALIDACION ONCHANGE REEMPLAZO Y PRESENCIAL
    if (funcion === 2 && e.target.value === 1) {
      let vd = moment(vigenciaDesde).format("DD/MM/YYYY");
      let vh = moment(vigenciaHasta).format("DD/MM/YYYY");
      setErrorMessageModalidad("");
      if (vd || vh === null) {
        setDelegacionDisabled(true);
      } else {
        setDelegacionDisabled(true);
        setErrorMessageActoAdministrativo("");
        setErrorMessageNroActoAdministrativo("");
      }
    }

    //VALIDACION ONCHANGE REEMPLAZO Y REMOTA
    if (funcion === 2 && e.target.value === 2) {
      let vd = moment(vigenciaDesde).format("DD/MM/YYYY");
      let vh = moment(vigenciaHasta).format("DD/MM/YYYY");
      setErrorMessageModalidad("");

      if (vh === "") {
        setErrorMessageFechaVigenciaHasta("Campo Obligatorio");
        setDelegacionDisabled(false);
      } else {
        setDelegacionDisabled(false);
      }
    }

    //VALIDACION ONCHANGE AUTORIDAD
    if ((funcion === 4 && e.target.value === 1) || e.target.value === 2) {
      setErrorMessageModalidad("");
    }
  };

  const onChangeDelegacion = (e) => {
    setDelegacion(e.target.value);
  };

  const onChangeCodCargo = (e) => {
    setCodCargo(e.target.value);
  };

  const onChangeCodActoAdministrativo = (e) => {
    setCodActoAdministrativo(e.target.value);
    if (e.target.value > 0) {
      setErrorMessageActoAdministrativo("");
    } else {
      setErrorMessageActoAdministrativo("Debe escoger el acto administrativo");
    }
  };

  const onChangeActivoSello = (e) => {
    setActivoSello(!activoSello);
  };

  const onChangeNroActoAdministrativo = (e) => {
    setNroActoAdministrativo(e.target.value);
    if (e.target.value !== "") {
      setErrorMessageNroActoAdministrativo("");
    } else {
      setErrorMessageNroActoAdministrativo(
        "Debe escoger el numero del acto administrativo"
      );
    }
  };

  const onChangeVigenciaDesde = (e) => {
    let vd = moment(e);
    let vh = moment(vigenciaHasta);

    if (moment(vd).isSameOrBefore(vh) === true) {
      setErrorMessageFechaVigenciaDesde("");
      setErrorMessageFechaVigenciaHasta("");
    } else {
      if (e === null) {
        setErrorMessageFechaVigenciaDesde("Campo Obligatorio");
      } else {
        setErrorMessageFechaVigenciaDesde("");
      }
    }
    setVigenciaDesde(e);
  };

  const onChangeVigenciaHasta = (e) => {
    let vd = moment(vigenciaDesde);
    let vh = moment(e);
    setErrorMessageFechaVigenciaHasta("");
    //VALIDACION GENERAL DE LAS FECHAS DE VIGENCIA DESDE Y HASTA
    if (vd.format("DD/MM/YYYY") <= vh.format("DD/MM/YYYY")) {
      setErrorMessageFechaVigenciaDesde("");
      setErrorMessageFechaVigenciaHasta("");
    } else {
      if (vh.format("DD/MM/YYYY") === "") {
        //setErrorMessageFechaVigenciaHasta('Fecha Invalida');
      } else {
        setErrorMessageFechaVigenciaHasta("");
      }
    }

    if (vd !== null && e === null) {
      setErrorMessageFechaVigenciaHasta("");
    }

    if (funcion === 1 && vd.format("DD/MM/YYYY") <= vh.format("DD/MM/YYYY")) {
      setErrorMessageFechaVigenciaHasta("");
    } else {
      //setErrorMessageFechaVigenciaHasta('Fecha Invalida');
    }

    //VALIDACION DE LA VIGENCIA HASTA CUANDO ESCOGA LA FUNCION DE REEMPLAZO
    if (funcion === 2 && vd.format("DD/MM/YYYY") <= vh.format("DD/MM/YYYY")) {
      setErrorMessageFechaVigenciaHasta("");
    }

    setVigenciaHasta(e);
  };

  //EN ESTA LINEA DE CODIGO PASAMOS EL FIRMANTE MEDIANTE UN PUSH Y AGREGAMOS EL LABEL Y EL VALUE
  //LOS CUALES SERAN RECUPERADOS EN EL CONTROL AUTOCOMPLETE
  let firmanteObjeto = [];
  FirmantesAltaList.map((firmante, index) =>
    firmanteObjeto.push({
      label: firmante.apellido + " " + firmante.nombre,
      value: firmante.codUsu,
    })
  );

  useEffect(() => {
    const data = {
      codUsu: decodeCodUsu,
      clave: decodeClave,
      ruta: rutaPermiso,
    };

    dispatch(postSeguridadAsync(data));
    if (Authenticated) {
      if (FirmantesAltaList.length === 0) {
        //llamo a la funcion del firmateSlice
        dispatch(getFirmantesAltaAsync());
      }

      if (FuncionList.length === 0) {
        dispatch(getFuncionAsync());
        //llamo a la funcion del firmateSlice
      }

      if (ModalidadList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getModalidadAsync());
      }

      if (DelegacionList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getDelegacionAsync());
      }

      if (ActoAdministrativoList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getActoAdministrativoAsync());
      }

      if (CargosList.length === 0) {
        //llamo a la funcion del Slice
        dispatch(getCargoAsync());
      }
    }
  }, []);

  const filtroNuevoSello = () => {
    history.push("/sellos");
  };

  //CREACION DE LA FUNCION POST, LA CUAL PERMITIRA ENVIAR LOS DATOS AL BACKEND Y POSTERIOR GUARDAR EN LA BASE DE DATOS
  const handleSubmitGuardarSello = () => {
    //aqui hay q armar el objeto con todo los datos del dispatch
    const res = {
      codUsu: firmante,
      codEnt: delegacion,
      codCargo: codCargo,
      codFuncion: funcion,
      codModalidad: modalidad,
      vigenciaDesde: vigenciaDesde,
      vigenciaHasta: vigenciaHasta,
      codActoAdministrativo: codActoAdministrativo,
      nroActoAdministrativo: nroActoAdministrativo,
      activoSello: activoSello,
      EncryptedUser: decodeCodUsu,
      EncryptedPassword: decodeClave,
    };

    const Alerta = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });

    Alerta.fire({
      title: "Alta de Sello",
      text: `DESEA GUARDAR LOS CAMBIOS?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "SI, GUARDAR",
      cancelButtonText: "NO, CANCELAR",
      reverseButtons: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let vd = moment(res.vigenciaDesde).startOf("day").toDate();
        let vh = moment(res.vigenciaHasta).startOf("day").toDate();
        const tipoFuncion = TipoFuncionAdministrativaFactory.crear(res.codFuncion);
        //UNA VEZ QUE CONFIRMAMOS LA ALERTA EMPEZARA UNA PROCESO DE VALIDACIONES
        //INICIANDO POR ESTE PRIMERO QUE ES DE FORMA GENERAL, SI O SI EN TODOS LOS CASOS SE DEBE CUMPLIR ESTA VALIDACION
        //EN TODOS LOS CASOS
        if (tipoFuncion.validacionPrevia(res.vigenciaDesde, res.codUsu, res.codEnt, res.codModalidad) 
          && (tipoFuncion.validarCamposObligatorios(res.codFuncion, res.vigenciaHasta, res.codActoAdministrativo, res.nroActoAdministrativo))
          && tipoFuncion.validacionVigencia(vd, vh, res.vigenciaDesde, res.vigenciaHasta)) {
            dispatch(postFiltroSellosAsync(res)).then(async (response) => {
              if (response.payload.results) {
                modalExitoSelloCreado(response.payload.data.descripCargoDigital, response.payload.data.descripCargo);
                await getFiltroSellosAsync();
              }
            });
        }          
      }
    });
  };

  return (
    <Container className={classes.containermt}>
      {/* <h4>Usuario: {username}</h4>
        <h4>Password: {password}</h4>   */}
      {status === "loading" ? (
        ""
      ) : (
        <Grid container justifyContent="center">
          <Grid item lg={12} md={8}>
            <Card className={classes.card} align="center">
              <Typography className={classes.tituloCabecera} variant="h4">
                ALTA DE SELLOS
              </Typography>
              {!Authenticated ? (
                <div>Usuario no autenticado</div>
              ) : (
                <form onSubmit={(e) => e.preventDefault()}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className={classes.gridmb}>
                      <div className={classes.datePicker}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            DateFnsUtils
                            name="vigenciaDesde"
                            inputFormat="dd/MM/yyyy"
                            //disableFuture
                            label="Vigencia Desde"
                            openTo="day"
                            value={vigenciaDesde}
                            onChange={(e) => onChangeVigenciaDesde(e)}
                            renderInput={(params) => <TextField {...params} />}
                          />
                          <h6 className={classes.errorMesage}>
                            {errorMessageFechaVigenciaDesde}
                          </h6>
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.gridmb}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          DateFnsUtils
                          name="vigenciaHasta"
                          inputFormat="dd/MM/yyyy"
                          //disableFuture
                          label="Vigencia Hasta"
                          openTo="day"
                          minDate={vigenciaDesde}
                          value={vigenciaHasta}
                          disabled={vigenciaHastaDisabled}
                          onChange={(e) => onChangeVigenciaHasta(e)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                        <h6 className={classes.errorMesage}>
                          {errorMessageFechaVigenciaHasta}
                        </h6>
                      </LocalizationProvider>
                    </Grid>

                    <Grid item md={5} xs={12} className={classes.gridmb}>
                      {/* <TextField
                    select
                    label="Firmante"
                    variant="standard"
                    fullWidth
                    align="left"
                    name="firmante"
                    value={firmante}
                    defaultValue={firmante}
                    onChange={(e) => onChangeFirmante(e)}
                >
                    {FirmantesList.map((firmante, index) => (
                    <MenuItem key={index} value={firmante.codUsu}>
                        {firmante.apellido + " " + firmante.nombre}
                    </MenuItem>
                    ))}
            </TextField> */}

                      <Autocomplete
                        disablePortal
                        name="Firmantes"
                        onChange={onChangeFirmante}
                        options={firmanteObjeto}
                        noOptionsText={"No se encuentra el Firmante"}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            label="Firmantes"
                          />
                        )}
                      />

                      <TextField
                        select
                        label="Función"
                        variant="standard"
                        fullWidth
                        align="left"
                        name="funcion"
                        value={funcion}
                        onChange={(e) => onChangeFuncionCargo(e)}
                      >
                        {FuncionList.map((funcion, index) => (
                          <MenuItem key={index} value={funcion.idFuncion}>
                            {funcion.descFuncion}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        label="Modalidad"
                        variant="standard"
                        fullWidth
                        align="left"
                        name="modalidad"
                        disabled={modalidadDisabled}
                        value={modalidad}
                        onChange={(e) => onChangeModalidad(e)}
                      >
                        {ModalidadList.map((modalidad, index) => (
                          <MenuItem key={index} value={modalidad.idModalidad}>
                            {modalidad.descModalidad}{" "}
                          </MenuItem>
                        ))}
                      </TextField>
                      <h6 className={classes.errorMesage}>
                        {errorMessageModalidad}
                      </h6>
                    </Grid>

                    <Grid item md={5} xs={12} className={classes.gridmb}>
                      <TextField
                        select
                        label="Delegación"
                        variant="standard"
                        fullWidth
                        align="left"
                        name="delegacion"
                        value={delegacion}
                        disabled={delegacionDisabled}
                        onChange={(e) => onChangeDelegacion(e)}
                      >
                        {DelegacionList.map((d, index) => (
                          <MenuItem key={index} value={d.codEnt}>
                            {d.razonSocial}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        label="Acto Administrativo"
                        variant="standard"
                        fullWidth
                        align="left"
                        disabled={actoAdministrativoDisabled}
                        name="actoAdministrativo"
                        value={codActoAdministrativo}
                        onChange={(e) => onChangeCodActoAdministrativo(e)}
                      >
                        <MenuItem value="">
                          <em>Ninguno</em>
                        </MenuItem>
                        {ActoAdministrativoList.map(
                          (actoAdministrativo, index) => (
                            <MenuItem
                              key={index}
                              value={actoAdministrativo.idActoAdministrativo}
                            >
                              {actoAdministrativo.descActoAdministrativo}{" "}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                      <h6 className={classes.errorMesage}>
                        {errorMessageActoAdministrativo}
                      </h6>

                      <FormGroup className={classes.espacioCheckbox}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => onChangeActivoSello(e)}
                              defaultChecked={true}
                              value={activoSello}
                            />
                          }
                          label="Activo"
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item md={2} xs={12}>
                      <div className={classes.espacioNroSello}>
                        <TextField
                          label="Nro."
                          variant="standard"
                          fullWidth
                          disabled={nroActoAdministrativoDisabled}
                          name="nroActoAdministrativo"
                          value={nroActoAdministrativo}
                          onChange={onChangeNroActoAdministrativo}
                        ></TextField>
                        <h6 className={classes.errorMesage}>
                          {errorMessageNroActoAdministrativo}
                        </h6>
                      </div>
                    </Grid>
                    <p className={classes.ocultarCodCargo}>Cargo: {codCargo}</p>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    className={classes.espacioBotonesFinales}
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        color="success"
                        onClick={() => handleSubmitGuardarSello()}
                        variant="contained"
                        fullWidth
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              { swalShown &&
                createPortal(
                    swalWithBootstrapButtons.getHtmlContainer() && 
                    <>
                        <br />
                        <br />
                        <br />
                        <VistaSello textoSello={selloEsDigital ? descripcionCargoDigital : descripcionCargoOlografo} esDigital={selloEsDigital} />
                    </> 
                    , swalWithBootstrapButtons.getHtmlContainer()
                )
            }
            </Card>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default AltaNuevoSello;
