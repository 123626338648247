import Swal from "sweetalert2";
import moment from "moment";

class TipoFuncionAdministrativa {
     
    validacionPrevia(vigenciaDesde, codUsu, codEnt, codModalidad) {
        const resul = vigenciaDesde !== null && codUsu !== "" && codEnt !== "" && codModalidad !== "" 
        if (!resul) {
            this.errorValidacionPrevia();
        } 
        return resul;
    }

    errorCamposObligatorios() {
        Swal.fire(
            "Mensaje!",
            `Debe llenar los campos obligatorios para la función de Reemplazo!`,
            "warning"
          );
    }

    errorValidacionPrevia() {
        Swal.fire(
            "Mensaje!",
            `Error al enviar los datos, No se han completado todos los datos necesarios para el de Alta de Sello!`,
            "warning"
          );
    }

    errorVigencia(vh, vd) {
        Swal.fire(
            "Mensaje!",
            `La Fecha de Vigencia Hasta no puede estar vacia, en el proceso de reemplazo es obligatoria!`,
            "warning"
          );
    }
}

export class SecretarioTecnico extends TipoFuncionAdministrativa {
    validarCamposObligatorios(vigenciaHasta, codActoAdministrativo, nroActoAdministrativo) {
        return true;
    }

    validacionVigencia(vd, vh, vigenciaDesde, vigenciaHasta){
        const resul = moment(vd).isSameOrBefore(vh) === true || vigenciaHasta === null;
        if (!resul)
            super.errorVigencia(vh, vd);
        return resul;
    }
}


export class Reemplazo extends TipoFuncionAdministrativa {
    
    validarCamposObligatorios(vigenciaHasta, codActoAdministrativo, nroActoAdministrativo) {
        const resul = vigenciaHasta !== null && codActoAdministrativo !== "" && nroActoAdministrativo !== "";
        if (!resul) 
            super.errorCamposObligatorios();
        return resul;
    }

    validacionVigencia(vd, vh, vigenciaDesde, vigenciaHasta){
        let resul = moment(vd).isSameOrBefore(vh) === true;
        if (!resul)
            super.errorVigencia(vh, vd);
        
        if (resul && (vd === "")) {
            resul = false;
            Swal.fire(
              "Mensaje!",
              `La Fecha de Vigencia Desde no puede estar vacia, en el proceso de reemplazo es obligatoria!`,
              "warning"
            );
        }
  
        if (resul && vigenciaHasta === null) {
            resul = false;
            Swal.fire(
                "Mensaje!",
                `La Fecha de Vigencia Hasta no puede estar vacia, en el proceso de reemplazo es obligatoria!`,
                "warning"
            );
        }

        return resul;
    }

    errorVigencia(vh, vd) {
        Swal.fire(
          "Mensaje!",
          `La Fecha de Vigencia Hasta ${vh.toLocaleDateString()} siempre tiene que ser mayor a la Fecha de Vigencia Desde ${vd.toLocaleDateString()}, revise el proceso de fechas y compárelo de forma correcta!`,
          "warning"
        );
    }

}


export class Apoyo extends TipoFuncionAdministrativa {
    
    validarCamposObligatorios(vigenciaHasta, codActoAdministrativo, nroActoAdministrativo) {
        const resul = vigenciaHasta !== null && codActoAdministrativo !== "" && nroActoAdministrativo !== "";
        if (!resul) 
            super.errorCamposObligatorios();
        return resul;
    }

    validacionVigencia(vd, vh, vigenciaDesde, vigenciaHasta){
        const resul = moment(vd).isSameOrBefore(vh) === true;

        if (!resul) {
            this.errorVigencia(vh, vd);
        }

        if (resul && (vigenciaDesde === null)) {
            resul = false;
            Swal.fire(
                "Mensaje!",
                `La Fecha de Vigencia Desde no puede estar vacia, en el proceso de función de apoyo es obligatoria!`,
                "warning"
            );
        }

        if (resul && vigenciaHasta === null) {
            resul = false;
            Swal.fire(
              "Mensaje!",
              `La Fecha de Vigencia Hasta no puede estar vacia, en el proceso de función de apoyo es obligatoria!`,
              "warning"
            );
        }
        return resul;
    }

    errorVigencia(vh, vd) {
        Swal.fire(
          "Mensaje!",
          `La Fecha de Vigencia Hasta ${vh.toLocaleDateString()} siempre tiene que ser mayor a la Fecha de Vigencia Desde ${vd.toLocaleDateString()}, revise el proceso de fechas y compárelo de forma correcta!`,
          "warning"
        );
      }
}


export class Autoridad extends TipoFuncionAdministrativa {
    
    validarCamposObligatorios(vigenciaHasta, codActoAdministrativo, nroActoAdministrativo) {
        return true;
    }

    validacionVigencia(vd, vh, vigenciaDesde, vigenciaHasta){
        const resul = moment(vd).isSameOrBefore(vh) === true || vigenciaHasta === null;
        if (!resul) {
            super.errorVigencia(vh);
        }
        if (resul && vigenciaDesde === null) {
            resul = false;
            Swal.fire(
              "Mensaje!",
              `La Fecha de Vigencia Desde no puede estar vacia, en el proceso de función de apoyo es obligatoria!`,
              "warning"
            );
          }
        return resul;
    }
}


export class TipoFuncionAdministrativaFactory {
    static crear(codFuncion) {
        let tipo;
        switch(codFuncion) {
            case 1:
              tipo = new SecretarioTecnico();
            break;
            case 2:
              tipo = new Reemplazo();
            break;
            case 3:
              tipo = new Apoyo(); 
            break;
            case 4:
              tipo = new Autoridad();
            break;
        }
        return tipo;
    }
}
