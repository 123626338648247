import { configureStore } from '@reduxjs/toolkit';
import {combineReducers} from "redux"; 
import thunk from 'redux-thunk';
import peliculasReducer from './features/Peliculas/peliculasSilice.js';
import firmantesReducer from './features/Firmante/firmanteSlice';
import firmantesAltaReducer from './features/FirmanteAlta/firmanteAltaSlice';
import cargosReducer from './features/Cargo/cargoSlice';
import modalidadReducer from './features/Modalidad/ModalidadSlice';
import delegacionReducer from './features/Delegacion/DelegacionSlice';
import actoAdministrativoReducer from './features/ActoAdministrativo/ActoAdministrativoSlice';
import funcionReducer from './features/Funcion/FuncionSlice';
import filtroSellosReducer from './features/Filtros/filtroSellosSlice';
import seguridadReducer from './features/Seguridad/SeguridadSlice';

const reducers = combineReducers({
  peliculasReducer,   
  firmantesReducer,
  firmantesAltaReducer,
  cargosReducer,
  modalidadReducer,
  delegacionReducer,
  actoAdministrativoReducer,
  funcionReducer,
  filtroSellosReducer,
  seguridadReducer       
});


export const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});

