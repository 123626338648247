//Layouts
import PrincipalLayout from 'layouts/principal';
import verSellos from 'pages/verSellos';
//Pages
import Home from 'pages/Home';
import NotFound from 'pages/NotFound';
import AltaNuevoSello from 'components/altaNuevoSello';
import SelectList from 'components/SelectList';
import VerDetalleSellos from 'components/verDetalleSello';
import EditarSellos from 'components/editarNuevoSello';
import ModificarSellos from 'components/filtroSellosModificar';
import BusquedaComitente from 'components/filtroBusquedaComitente';

//Classes
class ROUT {
    constructor(path, display, layout, page,classNames) {
      this.PATH = path;
      this.DISPLAY = display;
      this.LAYOUT = layout;
      this.PAGE = page;
      this.CLASS_NAME = classNames;
    }
}

//constantes internas
const HOME = new ROUT('/','HOME',PrincipalLayout, verSellos, 'verSellos');
const VERSELLOS = new ROUT('/verSellos','VERSELLOS',PrincipalLayout, verSellos, 'verSellos');
const ALTANUEVOSELLO = new ROUT('/AltaNuevoSello/','ALTANUEVOSELLO',PrincipalLayout, AltaNuevoSello, 'altaNuevoSello');
const VERDETALLESELLO = new ROUT('/DetalleSellos/:idSello','DETALLESELLOS',PrincipalLayout, VerDetalleSellos, 'detalle/:idSello');
//const EDITARSELLO = new ROUT('/EditarSellos/:idSello','EDITARESELLOS',PrincipalLayout, EditarSellos, 'editar/:idSello');
const MODIFICARSELLO = new ROUT('/ModificarSellos/','MODIFICARESELLOS',PrincipalLayout, ModificarSellos, 'modificarSellos');
const BUSQUEDA_COMITENTE = new ROUT('/BusquedaComitente','BUSQUEDA_COMITENTE',PrincipalLayout, BusquedaComitente, 'busquedaComitente');
const SELECT = new ROUT('/selectAnidado','SELECTANIDADO',PrincipalLayout, SelectList, 'selectAnidado');
const NOT_FOUND = new ROUT('*','No Disponible', PrincipalLayout, NotFound,'not-found');

//Exports
export const ROUTES = Object.freeze({
    HOME,
    NOT_FOUND,
    VERSELLOS,
    VERDETALLESELLO,
    ALTANUEVOSELLO,
    //EDITARSELLO,
    MODIFICARSELLO,
    BUSQUEDA_COMITENTE,
    SELECT,
    LIST: [
        HOME,
        VERSELLOS,
        VERDETALLESELLO,
        ALTANUEVOSELLO,
        //EDITARSELLO,
        MODIFICARSELLO,
        BUSQUEDA_COMITENTE,
        SELECT,
        NOT_FOUND //Dejar siempre en ultimo lugar!!!
    ]
})