import { GET_ACTO_ADMINISTRATIVO, HEADERS } from 'constants/endpoints';
import axios from 'axios';

export const getActoAdministrativo = () => {
	return new Promise((resolve, reject) => {
		return axios({
			url: GET_ACTO_ADMINISTRATIVO,
			method: 'GET',
			headers: HEADERS,
			// params: {
			// 	anio,
			// 	mes,
			// },
		})
			.then(response => response)
			.then(json => {
				return resolve({ data: json.data });
			})
			.catch(err => {
				console.log(err);
				return reject(err);
			});
	});
};